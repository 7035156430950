<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="给他充钱" left-text="返回" left-arrow @click-left="onClickLeft" style="font-size: 2.5rem;" />
    <!-- 卡号统计 -->
    <div class="statisticsDiv">
      <span class="statisticsSpan1">账户中财产</span>
      我的余额: <span class="statisticsSpan2">{{ recStatistics.money }}.00</span> 元
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">代充值统计</span>
      累积代充: <span class="statisticsSpan2">{{ recStatistics.num }}</span> 笔款项
    </div>
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <!-- 菜单区 -->
    <!-- 输入任意文本 -->
    <van-field v-model="depositMoney.nick" label="昵称: " placeholder="TA的昵称 (只读)" maxlength="10" readonly />
    <!-- 输入手机号，调起手机号键盘 -->
    <van-field v-model="depositMoney.hisacc" type="tel" label="账户: " placeholder="输入TA的账户" maxlength="11"
      @update:model-value="onUpdateValue('hisacc')" />
    <!-- 允许输入正整数，调起纯数字键盘 -->
    <van-field v-model="depositMoney.money" type="number" label="金额: " placeholder="输入充值金额" maxlength="10"
      @update:model-value="onUpdateValue('money')" />
    <van-button type="primary" style="margin-bottom: 1rem; height: 2.5rem; width: 20rem;"
      @click="onClickRechargeMoney">给他充值</van-button>
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <van-cell icon="completed-o" title="查看充值记录" is-link @click="onClickOpenMenu('查看充值记录')" />
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <div style="width: 100%; height: 2.0rem; line-height: 2.0rem; color: #0080ff; font-size: 0.6rem;">
      提示: 充值前一定要仔细核对TA的昵称，以免充错
    </div>
  </div>
</template>



<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { getHost, reactiveSafeUpdate, PostLoginInfo } from '@/api/Api';
import { showToast, showNotify } from 'vant';
import { useRouter } from 'vue-router';
import ClipboardJS from 'clipboard';
import axios from 'axios';



/**
 *  定义类型: 充值统计
 */
interface RechargeStatistics {
  num: string,    // 充值笔数
  money: string,  // 账户余额
}


/**
 *  定义类型: 给他充钱
 */
interface DepositMoney {
  phone: string,  // 他的昵称
  token: string,  // 他的账户
  nick: string,   // 他的昵称
  hisacc: string, // 他的账户
  money: string,  // 充值金额
}


export default defineComponent({
  components: {
  },

  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();

    // 声明类型: 登录信息提交
    let postLoginInfo = reactive<PostLoginInfo>({
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });
    // 声明类型: 充值统计
    let recStatistics = reactive<RechargeStatistics>({
      num: '0',
      money: '0',
    });
    // 声明类型: 给他充钱
    let depositMoney = reactive<DepositMoney>({
      phone: '',
      token: '',
      nick: '',
      hisacc: '',
      money: '',
    });


    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      onClickInitialization(); // 事件: 初始化
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      postLoginInfo.nick = localStorage.getItem('nick') || '';
      postLoginInfo.level = localStorage.getItem('level') || '';
      postLoginInfo.phone = localStorage.getItem('phone') || '';
      postLoginInfo.token = localStorage.getItem('token') || '';
      postLoginInfo.timee = localStorage.getItem('timee') || '';
      postLoginInfo.state = localStorage.getItem('state') || '';
      console.log('nick: ' + postLoginInfo.nick);
      console.log('level: ' + postLoginInfo.level);
      console.log('phone: ' + postLoginInfo.phone);
      console.log('token: ' + postLoginInfo.token);
      console.log('timee: ' + postLoginInfo.timee);
      console.log('state: ' + postLoginInfo.state);
      // 如果登录信息无效 - 则重新登录
      if (postLoginInfo.nick === '' || postLoginInfo.level === '' || postLoginInfo.phone === '' || postLoginInfo.token === '' || postLoginInfo.timee === '' || postLoginInfo.state !== '0') {
        // 使用路由跳转到登录页面
        routerClass.push({ name: 'd_al' });
        return;
      }
      rechargeStatistics();  // 管理员-给他充钱: 查询当前余额与充值统计
    }




    /**
     * 事件: 输入框中的内容发生了变化时
     */
    function onUpdateValue(tag: string) {
      if (tag === 'hisacc' && depositMoney.hisacc.length === 11) {
        console.log('检测TA的昵称');
        // 管理员-给他充钱: 获取对方的昵称
        getHisNickname();
      }

    }




    /**
     * 事件: 打开菜单
     */
    function onClickOpenMenu(tag: string) {
      console.log('打开菜单: ' + tag);
      // 使用路由跳转页面并传递参数
      routerClass.push({ name: 'd_gr' });
      return;
    }



    // 事件: 返回到上一页
    const onClickLeft = () => history.back();



    /**
     *  管理员-给他充钱: 查询当前余额与充值统计
     */
    async function rechargeStatistics() {
      if (postLoginInfo.phone.length !== 11 || postLoginInfo.token.length !== 64) {
        showToast('请重新登录');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=rechargeStatistics", postLoginInfo, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(recStatistics, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(recStatistics, parsedData.data);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 管理员-给他充钱: 获取对方的昵称
     */
    async function getHisNickname() {
      depositMoney.phone = postLoginInfo.phone;
      depositMoney.token = postLoginInfo.token;
      if (depositMoney.phone.length !== 11 || depositMoney.token.length !== 64) {
        showToast('请重新登录');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getHisNickname", depositMoney, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(depositMoney, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(depositMoney, parsedData.data);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 管理员-给他充钱: 给他充值
     */
    async function onClickRechargeMoney() {
      depositMoney.phone = postLoginInfo.phone;
      depositMoney.token = postLoginInfo.token;
      if (depositMoney.phone.length !== 11 || depositMoney.token.length !== 64) {
        showToast('请重新登录');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=rechargeMoney", depositMoney, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code === '0') {
          showToast('充值成功');
          showNotify({ type: 'success', message: '充值成功' });
          depositMoney.nick = '';
          depositMoney.hisacc = '';
          depositMoney.money = '';
          // 管理员-给他充钱: 查询当前余额与充值统计
          rechargeStatistics();
          return;
        } else {
          showToast('充值失败');
          showNotify({ type: 'danger', message: '异常: [' + parsedData.msg + ']' });
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    return {
      recStatistics,
      depositMoney,
      onClickLeft,
      onClickOpenMenu,
      onUpdateValue,
      onClickRechargeMoney,
    };
  },
});
</script>



<style>
/* 全局设置 */
:root {
  /* 设置全局图标大小为 24px */
  --van-cell-icon-size: 24px;
  --van-cell-font-size: 1.1rem
}



/** 统计 - DIV */
.statisticsDiv {
  font-size: 0.8rem;
  margin: 0.5rem;
  text-align: left;
  margin-bottom: 1rem;
}

/** 统计 - Span1 */
.statisticsSpan1 {
  font-weight: bold;
  margin-left: 1rem;
}

/** 统计 - Span2 */
.statisticsSpan2 {
  font-weight: bold;
  color: #0080ff;
}

/** 采购按钮 */
.purchaseButton {
  width: 15rem;
  height: 2.5rem;
  margin-bottom: 0.3rem;
}
</style>