<template>

    <!-- 作品海报详情 -->
    <van-row>
        <van-col span="10">
            <div
                :style="'position: relative; z-index: 1; width: ' + width + '; height: ' + height + '; line-height: ' + height + ';  border-radius: 50%; background-color: ' + bgClor + '; margin: 0.8rem;'">
                <span :style="'font-size: ' + fontSize + '; color: #000000; font-weight: bold;'">{{ text }}</span>
            </div>
        </van-col>
        <van-col span="14">
            <!-- 设置垂直间距 -->
            <van-row :gutter="[0, 10]" style="padding-top: 0.9rem;">
                <van-col span="22" class="left-align"><span>昵称: </span>{{ name }}</van-col>
                <van-col span="22" class="left-align"><span>级别: </span>{{ level }}</van-col>
                <van-col span="22" class="left-align"><span>账号: </span>{{ phone }}</van-col>
                <van-col span="22" class="left-align"><span>期限: </span>{{ timet }}</van-col>
            </van-row>
        </van-col>
    </van-row>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SvipIcon',
    props: {
        bgClor: {
            type: String,
            required: true,
            default: '#9f9f9f'
        },
        width: {
            type: String,
            required: true,
            default: '5rem'
        },
        height: {
            type: String,
            required: true,
            default: '5rem'
        },
        fontSize: {
            type: String,
            required: true,
            default: '1.6rem'
        },
        text: {
            type: String,
            required: true,
            default: 'SVIP'
        },
        level: {
            type: String,
            required: true,
            default: '客户'
        },
        name: {
            type: String,
            required: true,
            default: '昵称'
        },
        phone: {
            type: String,
            required: true,
            default: '18688889999'
        },
        timet: {
            type: String,
            required: true,
            default: '2025-09-01'
        }
    }
});
</script>

<style scoped>
/* 会员详情 */
.left-align {
    font-size: 1.0rem;
    text-align: left;
    /* 水平靠左 */
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 超出内容隐藏 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}
</style>