<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="分销统计" left-text="返回" left-arrow @click-left="onClickLeft" style="font-size: 2.5rem;" />
    <!-- 一周 -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      一周
    </van-divider>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">SVIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.svipAll1 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.svipSell1 }}</span> 张
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">&nbsp;&nbsp;VIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.vipAll1 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.vipSell1 }}</span> 张
    </div>
    <div style="width: 100%; height: 0.5rem; background-color: #f1f1f1;"></div>
    <!-- 本月 -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      本月
    </van-divider>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">SVIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.svipAll2 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.svipSell2 }}</span> 张
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">&nbsp;&nbsp;VIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.vipAll2 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.vipSell2 }}</span> 张
    </div>
    <div style="width: 100%; height: 0.5rem; background-color: #f1f1f1;"></div>
    <!-- 上月 -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      上月
    </van-divider>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">SVIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.svipAll3 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.svipSell3 }}</span> 张
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">&nbsp;&nbsp;VIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.vipAll3 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.vipSell3 }}</span> 张
    </div>
    <div style="width: 100%; height: 0.5rem; background-color: #f1f1f1;"></div>
    <!-- 本年 -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      本年
    </van-divider>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">SVIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.svipAll4 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.svipSell4 }}</span> 张
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">&nbsp;&nbsp;VIP 升级卡</span>
      采购: <span class="statisticsSpan2">{{ salesStatistics.vipAll4 }}</span> 张，售出: <span class="statisticsSpan2">{{
      salesStatistics.vipSell4 }}</span> 张
    </div>
    <div style="width: 100%; height: 0.5rem; background-color: #f1f1f1;"></div>
    <div
      style="width: 100%; height: 2.0rem; line-height: 2.0rem; color: #0080ff; font-size: 0.6rem; margin-top: 0.5rem; margin-bottom: 5.8rem;">
      同舟共济 合作共赢
    </div>
  </div>
</template>



<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { getHost, reactiveSafeUpdate, PostLoginInfo, clearCookies } from '@/api/Api';
import { showToast, showSuccessToast, showFailToast } from 'vant';
import { useRouter } from 'vue-router';
import axios from 'axios';


/**
 *  代理商查询: 分销统计数据
 */
interface SalesStatistics {
  svipAll1: string,   // 采购 SVIP 一周
  svipAll2: string,   // 采购 SVIP 本周
  svipAll3: string,   // 采购 SVIP 上周
  svipAll4: string,   // 采购 SVIP 本年
  svipSell1: string,  // 售出 SVIP 一周
  svipSell2: string,  // 售出 SVIP 本月
  svipSell3: string,  // 售出 SVIP 上月
  svipSell4: string,  // 售出 SVIP 本年
  vipAll1: string,    // 采购 VIP 一周
  vipAll2: string,    // 采购 VIP 本月
  vipAll3: string,    // 采购 VIP 上月
  vipAll4: string,    // 采购 VIP 本年
  vipSell1: string,   // 售出 VIP 一周
  vipSell2: string,   // 售出 VIP 本月
  vipSell3: string,   // 售出 VIP 上月
  vipSell4: string,   // 售出 VIP 本年
}


export default defineComponent({
  components: {
  },

  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();

    // 声明类型: 登录信息提交
    let postLoginInfo = reactive<PostLoginInfo>({
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });

    // 声明类型: 选择升级卡号
    let salesStatistics = reactive<SalesStatistics>({
      svipAll1: '',
      svipAll2: '',
      svipAll3: '',
      svipAll4: '',
      svipSell1: '',
      svipSell2: '',
      svipSell3: '',
      svipSell4: '',
      vipAll1: '',
      vipAll2: '',
      vipAll3: '',
      vipAll4: '',
      vipSell1: '',
      vipSell2: '',
      vipSell3: '',
      vipSell4: '',
    });




    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      onClickInitialization(); // 事件: 初始化
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      postLoginInfo.nick = localStorage.getItem('nick') || '';
      postLoginInfo.level = localStorage.getItem('level') || '';
      postLoginInfo.phone = localStorage.getItem('phone') || '';
      postLoginInfo.token = localStorage.getItem('token') || '';
      postLoginInfo.timee = localStorage.getItem('timee') || '';
      postLoginInfo.state = localStorage.getItem('state') || '';
      console.log('nick: ' + postLoginInfo.nick);
      console.log('level: ' + postLoginInfo.level);
      console.log('phone: ' + postLoginInfo.phone);
      console.log('token: ' + postLoginInfo.token);
      console.log('timee: ' + postLoginInfo.timee);
      console.log('state: ' + postLoginInfo.state);
      // 如果登录信息无效 - 则重新登录
      if (postLoginInfo.nick === '' || postLoginInfo.level === '' || postLoginInfo.phone === '' || postLoginInfo.token === '' || postLoginInfo.timee === '' || postLoginInfo.state !== '0') {
        // 使用路由跳转到登录页面
        routerClass.push({ name: 'd_al' });
        return;
      }
      getSalesStatistics();  // 代理商查询: 分销统计数据
    }


    /**
     * 事件: 退出登录
     */
    function onClickExitLogin() {
      // 清空Cookies. 令牌失效需要重新登录
      clearCookies();
      // 使用路由跳转到登录页面
      routerClass.push({ name: 'd_al' });
    }

    // 事件: 返回到上一页
    const onClickLeft = () => history.back();



    /**
     * 代理商查询: 分销统计数据
     */
    async function getSalesStatistics() {
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getSalesStatistics", postLoginInfo, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code !== '0') {
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TokenInvalid') {
            showToast('令牌失效，请重新登录');
            onClickExitLogin(); // 事件: 退出登录
            return;
          }
          showToast('异常代码: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(salesStatistics, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(salesStatistics, parsedData.data);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }



    return {
      salesStatistics,
      onClickLeft,
    };
  },
});
</script>



<style>
/* 全局设置 */
:root {
  /* 设置全局图标大小为 24px */
  --van-cell-icon-size: 24px;
  --van-cell-font-size: 1.1rem
}



/** 统计 - DIV */
.statisticsDiv {
  font-size: 0.8rem;
  margin: 0.5rem;
  text-align: left;
  margin-bottom: 1rem;
}

/** 统计 - Span1 */
.statisticsSpan1 {
  font-weight: bold;
  margin-left: 1rem;
}

/** 统计 - Span2 */
.statisticsSpan2 {
  font-weight: bold;
  color: #0080ff;
}

/** 采购按钮 */
.purchaseButton {
  width: 15rem;
  height: 2.5rem;
  margin-bottom: 0.3rem;
}
</style>