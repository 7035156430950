<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar :title="nameNow" left-text="返回" left-arrow @click-left="onClickLeft" />
    <!-- 即将自动播放下一课 -->
    <div v-if="showCode === 1" style="margin-top: 2rem; margin-bottom: 2rem;">
      <van-circle v-model:current-rate="currentRate" :size="248" :rate="100" :speed="33" :stroke-width="60"
        text="自动播放下一课" />
      <div style="margin-top: 1rem; font-size: 1rem; ">{{ nameNext }}</div>
    </div>
    <!-- 当前课程的播放器 -->
    <div v-show="showCode === 0" class="divVideoPlayer">
      <VideoPlayer :src="''" poster="" playerWidth="20rem" playerHeight="34rem" :autoplay="'true'"
        :isBigPlayButton="false" @message-to-player="handleMessageFromChild" ref="videoPlayerRef">
      </VideoPlayer>
    </div>
    <div>
    </div>
    <!-- 垫底空间填充 -->
    <div style="width: 100%; height: 5.8rem;"></div>
  </div>
</template>


<script lang="ts">
import { showToast, showConfirmDialog, showNotify } from 'vant';
import { ref, reactive, defineComponent, PropType, onMounted, onActivated, onDeactivated } from 'vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { getHost, ossOdlUrl, ossNewUrl, PostLoginInfo, clearCookies } from '@/api/Api';
import { useRouter } from 'vue-router';
import axios from 'axios';




export default defineComponent({
  name: 'ADetails',  // 在这里加上 name 属性
  components: {
    VideoPlayer
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '视频名称'
    }
  },


  setup(props) {
    const showCode = ref(0);
    const nameNow = ref('');
    const nameNext = ref('');
    let videoNow = '';
    let videoNext = '';
    const active = ref(0);
    const currentRate = ref(0);
    const videoPlayerRef = ref();
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();

    /**
     * 初始化
     */
    function initialization() {
      if (nameNow.value === '') nameNow.value = props.title;
      const courses = [
        {
          name: '01. 免剪到底是什么？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-01.mp4'
        },
        {
          name: '02. 如何免费体验？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-02.mp4'
        },
        {
          name: '03. 免费与付费的区别？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-03.mp4'
        },
        {
          name: '04. 如何办理会员卡？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-04.mp4'
        },
        {
          name: '05. 怎样挑选作品？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-05.mp4'
        },
        {
          name: '06. 如何下载作品？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-06.mp4'
        },
        {
          name: '07. 如何正确投稿？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-07.mp4'
        },
        {
          name: '08. 如何解决异常情况？',
          video: 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/jiaocheng/01-08.mp4'
        }
      ]

      for (let i = 0; i < courses.length; i++) {
        let item = courses[i];
        if (item.name === nameNow.value) {
          videoNow = item.video;
          if (i + 1 < courses.length) {
            nameNext.value = courses[i + 1].name;
            videoNext = courses[i + 1].video;
          } else {
            nameNext.value = '';
            videoNext = '';
          }
        }
      }

      console.log('nameNow: ' + nameNow.value);
      console.log('nameNext: ' + nameNext.value);
      console.log('videoNow: ' + videoNow);
      console.log('videoNext: ' + videoNext);

    }


    /**
     * 接收子播放器反馈的事件消息
     */
    const handleMessageFromChild = (msg: string) => {
      // console.log('msg: ' + msg);
      if (msg === 'initialize-yes') {
        console.log("msg: 播放器.子组件初始化完毕");
        // 首次解析播放列表
        initialization();
        videoPlayerRef.value?.initializePlayer(videoNow);
      }
      else if (msg === 'ended-yes') {
        console.log("msg: 视频播放结束");
        // 播放下一条视频
        if (nameNext.value.length > 0) {
          console.log('nameNext: ' + nameNext.value);
          console.log('videoNext: ' + videoNext);
          // 渲染倒计时提示信息
          currentRate.value = 0;
          showCode.value = 1;
          setTimeout(() => {
            showCode.value = 0;
            nameNow.value = nameNext.value;
            // 播放列表重新解析
            initialization();
            // 播放器重新初始化
            videoPlayerRef.value?.initializePlayer(videoNow);
          }, 3000);
        }
      }
    };




    const goBack = () => {
      // 使用浏览器后退
      routerClass.back();
    };



    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("onMounted.vue.组件挂载之后执行的方法");
    });


    onActivated(() => {
      console.log('ADetails is activated');
    });

    onDeactivated(() => {
      console.log('ADetails is deactivated');
    });





    // 点击事件: 返回上一页
    const onClickLeft = () => history.back();



    return {
      showCode,
      nameNow,
      nameNext,
      active,
      props,
      currentRate,
      videoPlayerRef,
      goBack,
      onClickLeft,
      handleMessageFromChild
    };
  },
});

</script>


<style>
/* 播放器 */
.divVideoPlayer {
  display: flex;
  /* 水平居中 */
  justify-content: center;
  margin-top: 0.5rem;

}
</style>