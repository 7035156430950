<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="代理服务商办理价目" left-text="返回" left-arrow @click-left="onClickLeft" />
    <!-- 九宫格海报列表 -->
    <van-grid :border="false" :column-num="2" class="van-grid">
      <van-grid-item v-for="(item, index) in svipItems" :key="index">
        <SvipCard :timeText="item.timeText" :discountPrice="item.discountPrice" :originalPrice="item.originalPrice"
          :discountTag="item.discountTag" />
      </van-grid-item>
    </van-grid>
    <!-- 自动义分割线 -->
    <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      style="margin-top: 3.0rem;">客服微信二维码</van-divider>
    <!-- 九宫格海报列表 -->
    <van-grid :border="false" :column-num="3" class="van-grid">
      <van-grid-item v-for="(item, index) in weChatItems" :key="index">
        <van-image width="6rem" height="6rem" :src="item.image" />
        <span style="font-size: 1.0rem; margin: 0.3rem;">{{ item.name }}</span>
      </van-grid-item>
    </van-grid>
    <div style="font-size: 1.1rem;">工作时间: 09:00 - 20:00</div>
    <div style="height: 188px;"></div>

    <!-- 嵌入子路由组件 -->
    <RouterView></RouterView>
  </div>
</template>




<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import SvipCard from '@/components/SvipCard.vue';


// 定义类型: 代理商价目
interface SvipItem {
  timeText: string,         // 年限
  discountPrice: string,    // 现价
  originalPrice: string,    // 原价
  discountTag: string,      // 折扣
}


// 定义类型: 客服信息类
interface WeChatItem {
  name: string,         // 客服名称
  image: string,        // 微信二维码
}



export default defineComponent({
  components: {
    SvipCard,
  },
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);

    const svipItems: SvipItem[] = [
      {
        timeText: '十年',
        discountPrice: '399999.00',
        originalPrice: '1999999.99',
        discountTag: '限时2折'
      }, {
        timeText: '三年',
        discountPrice: '179999.00',
        originalPrice: '599999.99',
        discountTag: '限时3折'
      }, {
        timeText: '二年',
        discountPrice: '159999.00',
        originalPrice: '399999.99',
        discountTag: '限时4折'
      }, {
        timeText: '一年',
        discountPrice: '99999.00',
        originalPrice: '199999.99',
        discountTag: '限时5折'
      },
    ]


    const weChatItems: WeChatItem[] = [
      {
        name: '小燕',
        image: require('@/assets/image/weChat1.png')
      },
      {
        name: '小雨',
        image: require('@/assets/image/weChat2.png')
      },
      {
        name: '小婧',
        image: require('@/assets/image/weChat3.png')
      },
    ]



    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");

    });


    // 点击事件: 返回上一页
    const onClickLeft = () => history.back();



    return {
      active,
      svipItems,
      weChatItems,
      onClickLeft,
    };
  },
});
</script>


<style>
/* 宫格列表 - 必须重置，否则有该死的 margin: 1rem */
.van-grid {
  padding: 0.1rem !important;
  margin: 0rem !important;
}
</style>