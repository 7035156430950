<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="作品广场" style="font-size: 2.5rem;" />
    <!-- 顶部分类标签 -->
    <van-tabs sticky swipeable v-model:active="active" @click-tab="onClickTab">
      <van-tab v-for="(item, index) in tags1" :key="index" :title="item"></van-tab>
    </van-tabs>
    <!-- 搜索与菜单 -->
    <van-row>
      <van-col span="16">
        <van-search v-model="searchValue" placeholder="请输入搜索关键词" />
      </van-col>
      <van-col span="4">
        <van-button plain type="primary" size="small" style="margin-top: 0.7rem; width: 88%; font-size: 0.8rem;"
          @click="onClickSearch">搜索</van-button>
      </van-col>
      <van-col span="4">
        <van-button plain type="primary" size="small" style="margin-top: 0.7rem; width: 88%; font-size: 0.8rem;"
          @click="onClickShowTags2">分类</van-button>
      </van-col>
    </van-row>
    <!-- 展开的分类按钮 -->
    <div v-if="showTop">
      <van-row v-for="(tags, index1) in tags2" :key="index1">
        <van-col span="4" v-for="(tag, index2) in tags" :key="index2">
          <van-button plain hairline type="default" size="mini" style="width: 92%; margin-top: 0.3rem;"
            @click="onClickTab2(tag)">{{ tag
            }}</van-button>
        </van-col>
      </van-row>
      <!-- 分割线 -->
      <div style="width: 100%; height: 0.5rem; background-color: #f7f8fa; margin-top: 1rem; margin-bottom: 1rem;"></div>
    </div>
    <!-- 自动追加与下拉刷新列表 -->
    <van-pull-refresh v-model="refreshList.refreshing" @refresh="onRefresh">
      <van-list v-model:loading="refreshList.loading" :finished="refreshList.finished" finished-text="没有更多了"
        @load="onLoad">
        <!-- 九宫格海报列表 -->
        <van-grid :border="false" :column-num="3" class="van-grid">
          <!-- 使用 v-for 循环遍历 gridItems 数组 -->
          <van-grid-item v-for="(item, index) in gridItems" :key="index" @click="openDetails(item)">
            <van-image width="6.3rem" height="10.0rem" :src="item.cover" />
            <!-- 覆盖的 span 标签 -->
            <span class="overlay-text-1">{{ item.sort1 }}</span>
            <span class="overlay-text-2">{{ item.sort2 }}</span>
            <span class="cover-title">{{ item.name }}</span>
          </van-grid-item>
        </van-grid>
      </van-list>
    </van-pull-refresh>
    <!-- 垫底空间填充 -->
    <div style="width: 100%; height: 5.8rem;"></div>
  </div>
</template>



<script lang="ts">
import { ref, reactive, defineComponent, onMounted, onActivated, onDeactivated } from 'vue';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import { getHost, ossOdlUrl, ossNewUrl } from '@/api/Api';
import axios from 'axios';



// 定义类型: 分类标签
interface Tab {
  title: string;
}

// 定义类型: 下拉刷新列表
interface RefreshList {
  inlet: string,        // 请求入口
  refreshing: boolean,  // 重刷状态
  loading: boolean,     // 加载状态
  finished: boolean,    // 完毕状态
  title: string,        // 分类标签
  page: number,         // 分页索引
  number: number        // 加载数量
}

// 定义类型: 九宫格列表
interface GridItem {
  sort: string;       // 分类标签
  sort1: string;      // 分类标签1
  sort2: string;      // 分类标签2
  keymd5: string;     // 短剧标识
  name: string;       // 短剧名称
  materials: string;  // 原始素材
  upload: string;     // 累计生产
  residue: string;    // 剩余作品
  cover: string;      // 作品封面
  timeu: string;      // 上架时间
}

// 定义类型: 路由跳转新页面
interface GotoPages {
  name: string,
  params: {
    title: string,      // 页面标题
    works: string,      // 作品名称
    sort: string,       // 分类标签
    sort1: string;      // 分类标签1
    sort2: string;      // 分类标签2
    image: string,      // 封面海报
    keyMd5: string      // 唯一Key
    materials: string;  // 原始素材
    upload: string;     // 累计生产
    residue: string;    // 剩余作品
    timeu: string;      // 上架时间
  }
}


export default defineComponent({
  name: 'IndexA',  // 在这里加上 name 属性
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(1);
    const showTop = ref(false);
    const searchValue = ref('');
    // 定义一个响应式的数组，用来存放数据
    const refreshList = ref<RefreshList>({
      inlet: '',
      refreshing: false,
      loading: false,
      finished: false,
      title: '分类标签',
      page: 1,
      number: 20
    });
    const gridItems = ref<GridItem[]>([]);
    const tags1 = ['免费', '新剧', '热门', '男频', '女频', '近现', '古装', '霸总', '重生', '复仇', '虐渣', '虐恋', '穿越', '穿书', '言情', '萌宝', '科幻', '玄幻', '武侠', '权谋', '逆袭', '甜宠', '豪门', '亲情', '闪婚', '马甲', '追妻', '脑洞', '神医', '乡村', '校园', '宫廷', '职场', '励志'];
    const tags2 = tags1.reduce<string[][]>((acc, curr, index) => {
      if (index % 6 === 0) {
        acc.push([]);
      }
      // 将当前元素添加到最后一个子数组
      acc[acc.length - 1].push(curr);
      return acc;
    }, []);


    // 组件生命周期: 组件首次创建
    onMounted(() => {
      console.log("组件生命周期.onMounted");
      // 定义初始化时查询的分类标签
      const tab: Tab = {
        title: '新剧'
      }
      // 事件: 点击了分类标签
      onClickTab(tab)
    });


    // 组件生命周期: 组件再次激活
    onActivated(() => {
      console.log('IndexA is activated');
    });


    // 组件生命周期: 组件将被缓存
    onDeactivated(() => {
      console.log('IndexA is deactivated');
    });



    /**
     * 获取: 分类下的封面图片
     * 
     * @param sort    分类标签
     * @param page    页面索引
     * @param number  加载数量
     * @param isEmpty 是否清空
     */
    async function getSortCover(refreshList: RefreshList, isEmpty: boolean) {
      searchValue.value = '';
      let postMpa = reactive({
        sort: refreshList.title,
        page: refreshList.page,
        number: refreshList.number
      })
      // 将 loading 设置为 true，表示是加载中
      refreshList.loading = true
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getSortCover", postMpa, {});
        if (isEmpty) {
          gridItems.value = [];
          // 清空列表数据 - 重置加载完毕状态
          refreshList.finished = false;
        }
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code !== '0') {
          showToast(parsedData.code);

          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TemporarilyNotRecords') {
            showToast('暂无记录');
            // 暂无记录 - 标记为加载完毕状态
            refreshList.finished = true;
            // 暂无记录 - 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            return;
          }
          showToast('异常代码: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            const items: GridItem[] = JSON.parse(parsedData.data);
            // 正序添加到 gridItems 中
            for (let i = 0; i < items.length; i++) {
              items[i].cover = items[i].cover.replace(ossOdlUrl(), ossNewUrl());
              gridItems.value.push(items[i]);
            }
            // 判断是否没有剩余数据, 注意只是可能
            if (items.length < refreshList.number) {
              // 可能没有更多数据 - 标记为加载完毕状态
              refreshList.finished = true;
            }
            // 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            refreshList.page++;
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
          // 加载数据失败 - 标记为加载完毕状态
          refreshList.finished = true;
          // 将 loading 设置为 false，表示非加载中
          refreshList.loading = false
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }



    /**
     * 获取: 搜索能够匹配的关键词
     * 
     * @param sort    分类标签
     * @param page    页面索引
     * @param number  加载数量
     * @param isEmpty 是否清空
     */
    async function getSearchText(refreshList: RefreshList, isEmpty: boolean) {
      if (searchValue.value.length < 1) {
        showToast('请输入有效内容');
        return;
      }
      showToast('搜索中');
      let postMpa = reactive({
        name: searchValue.value,
        page: refreshList.page,
        number: refreshList.number
      })
      // 将 loading 设置为 true，表示是加载中
      refreshList.loading = true
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getSearchText", postMpa, {});
        if (isEmpty) {
          gridItems.value = [];
          // 清空列表数据 - 重置加载完毕状态
          refreshList.finished = false;
        }
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code !== '0') {
          showToast(parsedData.code);

          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TemporarilyNotRecords') {
            showToast('暂无记录');
            // 暂无记录 - 标记为加载完毕状态
            refreshList.finished = true;
            // 暂无记录 - 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            return;
          }
          showToast('异常代码: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            const items: GridItem[] = JSON.parse(parsedData.data);
            // 正序添加到 gridItems 中
            for (let i = 0; i < items.length; i++) {
              items[i].cover = items[i].cover.replace(ossOdlUrl(), ossNewUrl());
              gridItems.value.push(items[i]);
            }
            // 判断是否没有剩余数据, 注意只是可能
            if (items.length < refreshList.number) {
              // 可能没有更多数据 - 标记为加载完毕状态
              refreshList.finished = true;
            }
            // 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            refreshList.page++;
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
          // 加载数据失败 - 标记为加载完毕状态
          refreshList.finished = true;
          // 将 loading 设置为 false，表示非加载中
          refreshList.loading = false
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }





    /**
     * 事件: 展开分类标签菜单
     */
    const onClickShowTags2 = () => {
      showTop.value = !showTop.value;
    };


    /**
     * 事件: 点击了分类标签
     */
    const onClickTab2 = (tag: string) => {
      active.value = tags1.indexOf(tag);
      showTop.value = false;
      refreshList.value.inlet = 'sort';
      refreshList.value.page = 1;
      refreshList.value.title = tag
      showToast(tag);
      // 获取: 分类下的封面图片
      getSortCover(refreshList.value, true)
    };


    /**
     * 事件: 点击了分类标签
     */
    const onClickTab = (tab: Tab) => {
      console.log(`title: ${tab.title}`);
      refreshList.value.inlet = 'sort';
      refreshList.value.page = 1;
      refreshList.value.title = tab.title;
      showToast(tab.title);
      // 获取: 分类下的封面图片
      getSortCover(refreshList.value, true)
    };



    /**
     * 事件: 搜索能够匹配的关键词
     */
    const onClickSearch = () => {
      // 获取: 分类下的封面图片
      refreshList.value.inlet = 'search';
      refreshList.value.page = 1;
      getSearchText(refreshList.value, true)
    };



    // 点击事件: 打开详情页面
    const openDetails = (obj: GridItem) => {
      console.log(JSON.stringify(obj));

      // 跳转到对应的子页面
      const myPage: GotoPages = {
        name: "a_ad",
        params: {
          title: "作品详情",
          works: obj.name,
          sort: obj.sort,
          sort1: obj.sort1,
          sort2: obj.sort2,
          image: obj.cover,
          keyMd5: obj.keymd5,
          materials: obj.materials,
          upload: obj.upload,
          residue: obj.residue,
          timeu: obj.timeu
        }
      };
      // 使用路由跳转页面并传递参数
      routerClass.push(myPage);
      // useRouter().push(myPage);
    }


    // 点击事件: 触底加载更多作品
    const onLoad = () => {
      if (refreshList.value.page == 1) {
        // 将 loading 设置为 false，表示已经加载完毕
        refreshList.value.loading = false
        return;
      }
      console.log('refreshList: ', refreshList);
      if (refreshList.value.inlet === 'sort') {
        // 获取: 分类下的封面图片
        getSortCover(refreshList.value, false);
      } else {
        // 获取: 搜索能够匹配的关键词
        getSearchText(refreshList.value, false);
      }
    };


    // 点击事件: 下拉刷新作品数据
    const onRefresh = () => {
      refreshList.value.page = 1;
      if (refreshList.value.inlet === 'sort') {
        // 获取: 分类下的封面图片
        getSortCover(refreshList.value, true);
      } else {
        // 获取: 搜索能够匹配的关键词
        getSearchText(refreshList.value, true);
      }
      // 将 refreshing 设置为 false，表示非加载中
      refreshList.value.refreshing = false;
    };




    return {
      tags1,
      tags2,
      active,
      showTop,
      searchValue,
      refreshList,
      gridItems,
      onClickSearch,
      onClickTab,
      onClickTab2,
      onClickShowTags2,
      onLoad,
      onRefresh,
      openDetails,
      getSortCover,
    };
  },
});
</script>


<style>
/* 宫格列表 - 必须重置，否则有该死的 margin: 1rem */
.van-grid {
  padding: 0.1rem !important;
  margin: 0rem !important;
}


/* 封面的标签 1 */
.overlay-text-1 {
  position: absolute;
  width: 5.8rem;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 文本颜色，可以根据需要修改 */
  color: white;
  /* 文本大小，可以根据需要调整 */
  font-size: 0.3rem;
  /* 半透明背景 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 内边距 */
  padding: 0.1rem 0.1rem;
  /* 圆角边框 */
  border-radius: 0.2rem;
}

/* 封面的标签 2 */
.overlay-text-2 {
  position: absolute;
  width: 5.8rem;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 文本颜色，可以根据需要修改 */
  color: white;
  /* 文本大小，可以根据需要调整 */
  font-size: 0.3rem;
  /* 半透明背景 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 内边距 */
  padding: 0.1rem 0.1rem;
  /* 圆角边框 */
  border-radius: 0.2rem;
}

/* 封面的标题 */
.cover-title {
  margin-top: 0.3rem;
  font-size: 0.5rem;
  /* 动态宽度 */
  width: 5.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>