<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="作品管理" left-text="返回" left-arrow @click-left="onClickLeft" style="font-size: 2.5rem;" />
    <!-- 自动追加与下拉刷新列表 -->
    <van-pull-refresh v-model="refreshList.refreshing" @refresh="onRefresh">
      <van-list v-model:loading="refreshList.loading" :finished="refreshList.finished" finished-text="没有更多了"
        @load="onLoad">
        <WorkManage v-for="(item, index) in worksItems" :key="index" :ida="item.id" :sort="item.sort"
          :works="item.works" :name="item.name" :duration="item.duration" :cover="item.cover" :image="item.image"
          :video="item.video" :downl="item.downl" :timeu="item.timeu" :timet="item.timet" :timed="item.timed"
          :keymd5="item.keymd5" />
      </van-list>
    </van-pull-refresh>
    <!-- 底部填充一些高度 -->
    <div style="height: 10rem;"></div>
  </div>
</template>



<script lang="ts">
import { ref, reactive, PropType, defineComponent, onMounted } from 'vue';
import { getHost, ossOdlUrl, ossNewUrl, reactiveSafeUpdate, PostLoginInfo, clearCookies } from '@/api/Api';
import { showToast, showSuccessToast, showFailToast } from 'vant';
import WorkManage from '@/components/WorkManage.vue';
import { useRouter } from 'vue-router';
import ClipboardJS from 'clipboard';
import axios from 'axios';




// 定义类型: 下拉刷新列表
interface RefreshList {
  refreshing: boolean,  // 重刷状态
  loading: boolean,     // 加载状态
  finished: boolean,    // 完毕状态
  phone: string,        // 登录账户
  token: string,        // 登录令牌
  func: string,         // 查询接口
  sort: string,         // 分类标签 copy || record
  page: number,         // 分页索引
  number: number        // 加载数量
}



// 定义类型: 我的作品列表
interface WorksItem {
  id: string;       // 记录编号
  sort: string;     // 剧情分类
  works: string;    // 短剧名称
  account: string;  // 所属用户
  keymd5: string;   // 短剧标识
  name: string;     // 作品名称
  duration: string; // 时长秒数
  cover: string;    // 短剧封面
  image: string;    // 作品封面
  video: string;    // 作品预览
  downl: string;    // 下载作品
  timeu: string;    // 上架时间
  timet: string;    // 拿下时间
  timed: string;    // 下载时间
}




export default defineComponent({
  components: {
    WorkManage,
  },

  props: {
    title: {
      type: String as PropType<string>,
      default: '页面标题'
    },
    show: {
      type: String as PropType<string>,
      default: '显示类型'
    }
  },
  setup(props) {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();

    // 声明类型: 下拉刷新列表
    const refreshList = reactive<RefreshList>({
      refreshing: false,
      loading: false,
      finished: false,
      phone: '',
      token: '',
      func: '',
      sort: '',
      page: 1,
      number: 20
    });
    // 声明类型: 登录信息提交
    let postLoginInfo = reactive<PostLoginInfo>({
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });
    // 声明类型: 我的作品列表
    const worksItems = ref<WorksItem[]>([]);


    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      onClickInitialization(); // 事件: 初始化
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      postLoginInfo.nick = localStorage.getItem('nick') || '';
      postLoginInfo.level = localStorage.getItem('level') || '';
      postLoginInfo.phone = localStorage.getItem('phone') || '';
      postLoginInfo.token = localStorage.getItem('token') || '';
      postLoginInfo.timee = localStorage.getItem('timee') || '';
      postLoginInfo.state = localStorage.getItem('state') || '';
      /*
      console.log('nick: ' + postLoginInfo.nick);
      console.log('level: ' + postLoginInfo.level);
      console.log('phone: ' + postLoginInfo.phone);
      console.log('token: ' + postLoginInfo.token);
      console.log('timee: ' + postLoginInfo.timee);
      console.log('state: ' + postLoginInfo.state);
      */
      // 如果登录信息无效 - 则重新登录
      if (postLoginInfo.nick === '' || postLoginInfo.level === '' || postLoginInfo.phone === '' || postLoginInfo.token === '' || postLoginInfo.timee === '' || postLoginInfo.state !== '0') {
        // 使用路由跳转到登录页面
        routerClass.push({ name: 'd_al' });
        return;
      }
      refreshList.phone = postLoginInfo.phone;
      refreshList.token = postLoginInfo.token;
      // 点击事件: 下拉刷新作品数据
      onRefresh();
    }


    // 点击事件: 下拉刷新作品数据
    const onRefresh = () => {
      refreshList.page = 1;
      // 登录用户: 获取个人收藏过的作品列表
      getMyCollectWorksList(true);
      // 将 refreshing 设置为 false，表示非加载中
      refreshList.refreshing = false;
    };


    // 点击事件: 触底加载更多作品
    const onLoad = () => {
      if (refreshList.page == 1) {
        // 将 loading 设置为 false，表示已经加载完毕
        refreshList.loading = false
        return;
      }
      // 登录用户: 获取个人收藏过的作品列表
      getMyCollectWorksList(false);
    };




    /**
     * 事件: 退出登录
     */
    function onClickExitLogin() {
      // 清空Cookies. 令牌失效需要重新登录
      clearCookies();
      // 使用路由跳转到登录页面
      routerClass.push({ name: 'd_al' });
    }


    // 事件: 返回到上一页
    const onClickLeft = () => history.back();




    /**
     * 登录用户: 获取个人收藏过的作品列表
     */
    async function getMyCollectWorksList(isEmpty: boolean) {
      // 将 loading 设置为 true，表示是加载中
      refreshList.loading = true
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getMyCollectWorksList", refreshList, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        if (isEmpty) {
          worksItems.value = [];
          // 清空列表数据 - 重置加载完毕状态
          refreshList.finished = false;
        }
        let parsedData = response.data
        if (parsedData.code !== '0') {
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TokenInvalid') {
            showToast('令牌失效，请重新登录');
            onClickExitLogin(); // 事件: 退出登录
            return;
          }
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TemporarilyNotRecords') {
            showToast('暂无记录');
            // 暂无记录 - 标记为加载完毕状态
            refreshList.finished = true;
            // 暂无记录 - 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            return;
          }
          showToast('异常代码: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            const items: WorksItem[] = JSON.parse(parsedData.data);
            // 正序添加到 worksItems 中
            for (let i = 0; i <= items.length; i++) {
              items[i].cover = items[i].cover.replace(ossOdlUrl(), ossNewUrl());
              items[i].image = items[i].image.replace(ossOdlUrl(), ossNewUrl());
              items[i].video = items[i].video.replace(ossOdlUrl(), ossNewUrl());
              items[i].downl = items[i].downl.replace(ossOdlUrl(), ossNewUrl());
              items[i].duration = formatTime(Number(items[i].duration));
              worksItems.value.push(items[i]);
            }
            // 判断是否可能还有剩余数据, 注意只是可能
            if (items.length < refreshList.number) {
              // 可能没有更多数据 - 标记为加载完毕状态
              refreshList.finished = true;
            }
            // 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            refreshList.page++;
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
          // 加载数据失败 - 标记为加载完毕状态
          refreshList.finished = true;
          // 将 loading 设置为 false，表示非加载中
          refreshList.loading = false
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 将秒数转为时间
     * 
     * @param seconds 秒数
     */
    function formatTime(seconds: number): string {
      // 计算小时、分钟和秒
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      // 补零格式化为两位数
      const formatNumber = (num: number): string => num.toString().padStart(2, '0');
      // 返回格式化后的时间字符串
      return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(secs)}`;
    }




    return {
      props,
      worksItems,
      refreshList,
      onLoad,
      onRefresh,
      onClickLeft,
    };
  },
});
</script>



<style>
/* 全局设置 */
:root {
  /* 设置全局图标大小为 24px */
  --van-cell-icon-size: 24px;
  --van-cell-font-size: 1.1rem
}



/** 统计 - DIV */
.statisticsDiv {
  font-size: 0.8rem;
  margin: 0.5rem;
  text-align: left;
  margin-bottom: 1rem;
}

/** 统计 - Span1 */
.statisticsSpan1 {
  font-weight: bold;
  margin-left: 1rem;
}

/** 统计 - Span2 */
.statisticsSpan2 {
  font-weight: bold;
  color: #0080ff;
}

/** 采购按钮 */
.purchaseButton {
  width: 15rem;
  height: 2.5rem;
  margin-bottom: 0.3rem;
}
</style>