<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="教学课堂" style="font-size: 2.5rem;" />

    <div style="margin: 1rem; font-size: 0.8rem; color: red; ">细看教程，少走弯路...</div>
    <!-- 使用 v-for 循环遍历 teachings 数组 -->
    <div v-for="(item, index) in teachings" :key="index" style="text-align: left;">
      <div style=" margin: 1rem; font-weight: bolder; font-size: 1.2rem;">{{ item.chapter }}</div>
      <!-- 使用 v-for 循环遍历 teachings.course 数组 -->
      <div v-for="(item2, index) in item.course" :key="index">
        <div style=" margin: 0.5rem; margin-left: 3rem; font-size: 1rem; cursor: pointer;"
          @click="openTeaching(item2.name)">
          <van-icon name="play-circle-o" />
          <span style="margin-left: 0.5rem; color: #1989fa;">{{ item2.name }}</span>
        </div>
      </div>
    </div>


    <!-- 嵌入子路由组件 -->
    <RouterView></RouterView>

  </div>
</template>


<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import { getHost, ossOdlUrl, ossNewUrl } from '@/api/Api';
import axios from 'axios';




export default defineComponent({
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);

    const teachings = [
      {
        chapter: '第一章: 实际操作篇',
        course: [
          {
            name: '01. 免剪到底是什么？'
          },
          {
            name: '02. 如何免费体验？'
          },
          {
            name: '03. 免费与付费的区别？'
          },
          {
            name: '04. 如何办理会员卡？'
          },
          {
            name: '05. 怎样挑选作品？'
          },
          {
            name: '06. 如何下载作品？'
          },
          {
            name: '07. 如何正确投稿？'
          },
          {
            name: '08. 如何解决异常情况？'
          }
        ]
      }
    ]

    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
    });




    // 点击事件: 打开详情页面
    const openTeaching = (name: string) => {
      // 跳转到对应的子页面
      const myPage = {
        name: "b_bt",
        params: {
          title: name,
        }
      };
      // 使用路由跳转页面并传递参数
      routerClass.push(myPage);
    }


    return {
      active,
      teachings,
      openTeaching,
    };
  },
});
</script>


<style></style>