<template>
    <div style="margin: 16px; font-size: 0.9rem; border: 1px solid skyblue; border-radius: 1rem;">
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">年限类型:
            </span>{{ title }}</div>
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">采购时间:
            </span>{{ timen }}</div>
        <van-field readonly v-model="cardValue" rows="2" autosize type="textarea" maxlength="64"
            placeholder="请输入您购买的升级卡号" show-word-limit />
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">使用时间:
            </span>{{ timeb }}</div>
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem; margin-bottom: 0.6rem;"><span
                style="font-weight: bold;">使用账户:
            </span>{{ phone }}</div>
    </div>
</template>

<script lang="ts">
import { showToast, showSuccessToast, showFailToast } from 'vant';
import { ref, defineComponent, onMounted } from 'vue'
import ClipboardJS from 'clipboard';


// 查看卡号
export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
            default: '年限类型'
        },
        timen: {
            type: String,
            required: true,
            default: '创建时间'
        },
        value: {
            type: String,
            required: true,
            default: '卡号内容'
        },
        timeb: {
            type: String,
            required: true,
            default: '使用时间'
        },
        phone: {
            type: String,
            required: true,
            default: '使用账户'
        }
    },
    setup(props) {
        let cardValue = ref('');

        // 组件生命周期: 组件挂载之后执行的方法
        onMounted(() => {
            console.log("组件挂载之后执行的方法");
            cardValue.value = props.value;
        });



        /**
         * 事件: 定义复制文本的函数
         * 
         * @param textToCopy 复制的内容
         */
        function onClickCopyText(textToCopy: string) {
            let clipboard: ClipboardJS = new ClipboardJS('.van-button', {
                text: () => textToCopy,
            });
            clipboard.on('success', () => {
                // 正确调用 Toast
                showSuccessToast('复制成功！');
            });
            clipboard.on('error', () => {
                // 正确调用 Toast
                showFailToast('复制失败');
            });
        }



        return {
            cardValue,
            onClickCopyText,
        };
    }
})
</script>

<style scoped>
/* 这里可以添加你想要的自定义样式 */
</style>