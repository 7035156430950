import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1ed0fc01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative","z-index":"1","width":"100%","height":"11rem","border-radius":"10px","background-color":"#f7f7f7","margin":"0.2rem"} }
const _hoisted_2 = { style: {"width":"100%","height":"4rem","line-height":"4rem","text-align":"center"} }
const _hoisted_3 = { style: {"font-size":"1.6rem","color":"#000","font-weight":"bold"} }
const _hoisted_4 = { style: {"width":"100%","height":"2rem","line-height":"2rem","text-align":"center"} }
const _hoisted_5 = { style: {"font-size":"1.2rem","color":"#f28f24","font-weight":"bold"} }
const _hoisted_6 = { style: {"width":"100%","height":"1.5rem","line-height":"1.5rem","text-align":"center"} }
const _hoisted_7 = { style: {"font-size":"0.9rem","color":"#686868","text-decoration":"line-through"} }
const _hoisted_8 = { style: {"width":"100%","height":"3rem","line-height":"3rem","text-align":"center"} }
const _hoisted_9 = { style: {"font-size":"1.0rem","color":"#f28f24"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.timeText), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, "￥" + _toDisplayString(_ctx.discountPrice), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, "￥" + _toDisplayString(_ctx.originalPrice), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.discountTag), 1)
    ])
  ]))
}