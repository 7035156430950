
/* 第一步：引入 createRouter 路由对象 */
import { Router, createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';




/* 第二步: 引入路由页面 */

// 四大板块 - 作品
import IndexA from '../views/IndexA.vue'
// 四大板块 - 教学
import IndexB from '../views/IndexB.vue'
// 四大板块 - 服务
import IndexC from '../views/IndexC.vue'
// 四大板块 - 我的
import IndexD from '../views/IndexD.vue'


// 功能页面 - 作品广场 - 作品详情页
import ADetails from '../views/IndexA/ADetails.vue'

// 功能页面 - 教学课堂 - 教程播放页
import BTeaching from '../views/IndexB/BTeaching.vue'

// 功能页面 - 服务在线 - 代理商价目
import ASvips from '../views/IndexC/ASvips.vue'
import BVips from '../views/IndexC/BVips.vue'
import CReason from '../views/IndexC/CReason.vue'
import ALogin from '../views/IndexD/ALogin.vue'
import BCard from '../views/IndexD/BCard.vue'
import CRecord from '../views/IndexD/CRecord.vue'
import DCount from '../views/IndexD/DCount.vue'
import EWorks from '../views/IndexD/EWorks.vue'
import FDeposit from '../views/IndexD/FDeposit.vue'
import GRecord from '../views/IndexD/GRecord.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'indexa',
    path: '/',          // 首页
    component: IndexA,
    props: true,
    meta: {
      isAuth: false,
      title: '作品广场'
    }
  },
  {
    name: 'indexb',
    path: '/b',
    component: IndexB,
    props: true,
    meta: {
      isAuth: false,
      title: '教学课堂'
    }
  },
  {
    name: 'indexc',
    path: '/c',
    component: IndexC,
    props: true,
    meta: {
      isAuth: false,
      title: '服务在线'
    }
  },
  {
    name: 'indexd',
    path: '/d',
    component: IndexD,
    props: true,
    meta: {
      isAuth: false,
      title: '会员中心'
    }
  },
  {
    name: 'a_ad',
    path: '/a/ad/:title/:works/:sort/:sort1/:sort2/:image/:keyMd5/:materials/:upload/:residue/:timeu',
    component: ADetails,
    props: true,
    meta: {
      isAuth: false,
      title: '作品详情'
    }
  },
  {
    name: 'b_bt',
    path: '/b/bt/:title',
    component: BTeaching,
    props: true,
    meta: {
      isAuth: false,
      title: '教学播放'
    }
  }
  ,
  {
    name: 'c_as',
    path: '/c/as',
    component: ASvips,
    props: true,
    meta: {
      isAuth: false,
      title: '代理商价目'
    }
  },
  {
    name: 'c_bv',
    path: '/c/bv',
    component: BVips,
    props: true,
    meta: {
      isAuth: false,
      title: '高级会员价目'
    }
  },
  {
    name: 'c_cr',
    path: '/c/cr',
    component: CReason,
    props: true,
    meta: {
      isAuth: false,
      title: '收费原因'
    }
  },
  {
    name: 'd_al',
    path: '/d/al',
    component: ALogin,
    props: true,
    meta: {
      isAuth: false,
      title: '登录账号'
    }
  },
  {
    name: 'd_bc',
    path: '/d/bc',
    component: BCard,
    props: true,
    meta: {
      isAuth: false,
      title: '卡号管理'
    }
  },
  {
    name: 'd_cr',
    path: '/d/cr/:title/:show',
    component: CRecord,
    props: true,
    meta: {
      isAuth: false,
      title: '卡号记录'
    }
  },
  {
    name: 'd_dc',
    path: '/d/dc',
    component: DCount,
    props: true,
    meta: {
      isAuth: false,
      title: '分销统计'
    }
  },
  {
    name: 'd_ew',
    path: '/d/ew',
    component: EWorks,
    props: true,
    meta: {
      isAuth: false,
      title: '作品管理'
    }
  },
  {
    name: 'd_fd',
    path: '/d/fd',
    component: FDeposit,
    props: true,
    meta: {
      isAuth: false,
      title: '给他充钱'
    }
  },
  {
    name: 'd_gr',
    path: '/d/gr',
    component: GRecord,
    props: true,
    meta: {
      isAuth: false,
      title: '充值记录'
    }
  }
];







/**
 * 创建路由实例
 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


/**
 * 类型化路由守卫的参数：
 * 
  beforeEach 的回调函数接收三个参数：
  to: 即将进入的路由对象，类型为 RouteLocationNormalized。
  from: 当前导航正要离开的路由对象，类型为 RouteLocationNormalized。
  next: 用于继续导航的函数，类型为 NavigationGuardNext。
 */


/**
 * 创建【全局前置】路由守卫: 在【初始化】时 + 每次页面【切换之前】触发
 */
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // 判断客户端是否拥有已登录的状态
  if (to.meta.isAuth && !isAuthenticated()) {
    alert('您还没有登录，请先登录！');
    next('/login');
  } else {
    next();
  }
});


/**
 * 创建【全局后置】路由守卫: 在【初始化】时 + 每次页面【切换之后】触发
 */
router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (to.meta.title) {
    document.title = to.meta.title as string; // 强制转换为 string 类型
    document.title = '免剪';
  }
});


/**
 * 判断用户是否已登录
 * 
 * @returns 已登录返回: true， 否则返回: false
 */
function isAuthenticated(): boolean {
  const admin = localStorage.getItem('admin');
  const token = localStorage.getItem('token');
  return admin !== null && token !== null;
}

export default router
