<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="登录账号" style="font-size: 2.5rem;" />
    <!-- 顶部弹出 -->
    <van-popup v-model:show="showTop" position="top" :style="{ height: '14rem' }">
      <van-nav-bar title="图片验证码" style="font-size: 2.5rem;" />
      <van-space v-if="imageVerify.verImage !== 'null'" style="margin-top: 1rem; margin-bottom: 1rem;">
        <van-image width="8rem" height="4rem" :src="imageVerify.verImage" @click="onClickGetVerifyImage" />
        <van-field v-model="smsVerify.verCode" type="digit" placeholder="输入4位数字验证码" maxlength="4" />
      </van-space>
      <van-button type="primary" style="width: 100%;" @click="onClickGetMsmCode">提交</van-button>
    </van-popup>

    <!-- 输入手机号，调起手机号键盘 -->
    <van-field v-model="smsVerify.verPhone" type="tel" label="手机号" placeholder="请输入手机号" maxlength="11" />
    <van-cell-group>
      <van-field v-model="postLoginInfo.smsCode" type="digit" label="验证码" placeholder="请输入验证码" maxlength="4">
        <template #button>
          <van-button size="small" type="primary" @click="onClickGetVerifyImage">发送验证码</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-button type="success" style="width: 100%;" @click="onClickGetLoginInfo">登录/注册</van-button>
    <div style="width: 100%; height: 2.0rem; line-height: 2.0rem; color: #0080ff; font-size: 0.6rem;">
      提示: 若您的手机未在本站注册，首登时将自动注册
    </div>
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
  </div>
</template>


<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import { getHost, reactiveSafeUpdate } from '@/api/Api';
import axios from 'axios';



// 定义类型: 图片验证码
interface ImageVerify {
  verToken: string,   // 令牌
  verImage: string,   // 图像
}

// 定义类型: 短信验证码
interface SmsVerify {
  verCode: string,    // 代码
  verToken: string,   // 令牌
  verPhone: string,   // 手机
}


// 定义类型: 登录信息提交
interface PostLoginInfo {
  smsCode: string,    // 代码
  smsToken: string,   // 令牌
  smsPhone: string,   // 手机
}

// 定义类型: 登录信息接收
interface ResLoginInfo {
  nick: string,    // 昵称
  level: string,   // 级别
  phone: string,   // 登录账户
  token: string,   // 登录令牌
  timee: string,   // 到期时间
  state: string,   // 冻结状态
}


export default defineComponent({
  components: {
  },

  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);
    let showTop = ref(false);
    let imageVerify = reactive<ImageVerify>({
      verToken: '令牌',
      verImage: 'null'
    });
    let smsVerify = reactive<SmsVerify>({
      verCode: '代码',
      verToken: '令牌',
      verPhone: '手机'
    });
    let postLoginInfo = reactive<PostLoginInfo>({
      smsCode: '代码',
      smsToken: '令牌',
      smsPhone: '手机'
    });
    let resLoginInfo = reactive<ResLoginInfo>({
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });




    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      smsVerify.verPhone = '';
    });



    /**
     * 事件: 获取图片验证码
     */
    function onClickGetVerifyImage() {
      if (smsVerify.verPhone.length < 11) {
        showToast('手机号码无效，请填写手机号');
        return;
      }
      showTop.value = true;
      getVerifyImage(); // 获取: 图片验证码
    }


    /**
     * 事件: 获取短信验证码
     */
    function onClickGetMsmCode() {
      getMsmCode(); // 获取: 短信验证码
    }


    /**
     * 事件: 登录信息
     */
    function onClickGetLoginInfo() {
      getLoginInfo(); // 获取: 登录信息
    }


    /**
     * 获取: 图片验证码
     */
    async function getVerifyImage() {
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getVerifyImage", {}, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(imageVerify, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(imageVerify, parsedData.data);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 获取: 短信验证码
     */
    async function getMsmCode() {
      if (smsVerify.verCode.length !== 4) {
        showToast('请先输入图片验证码');
        return;
      }
      smsVerify.verToken = imageVerify.verToken;
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getMsmCode", smsVerify, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code === '-20002') {
          smsVerify.verCode = '';
          getVerifyImage(); // 获取: 图片验证码
          showToast('图片验证码错误，请重新输入');
          return;
        }
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        /*
        {
        "code": "0",
        "msg": "success",
        "timestamp": 1725958149547,
        "data": {
            "msmCode": "0",
            "smsToken": "2ff5320a116a5b85af0cc85201242e19",
            "smsPhone": "18655456683"
        }
        */
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(postLoginInfo, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(postLoginInfo, parsedData.data);
          }
          // 如果验证码发送成功
          if (postLoginInfo.smsCode === '0') {
            showTop.value = false;
            postLoginInfo.smsCode = '';
            showToast('短信验证码发送成功, 请留意查收');
          } else {
            showToast('验证码系统故障, 请联系管理员');
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 获取: 登录信息
     */
    async function getLoginInfo() {
      if (postLoginInfo.smsCode.length !== 4) {
        showToast('请先输入短信验证码');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getLoginInfo", postLoginInfo, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(resLoginInfo, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(resLoginInfo, parsedData.data);
          }
          // 如果账户登录成功
          if (resLoginInfo.state === '0') {
            showToast('登录成功');
            localStorage.setItem('nick', resLoginInfo.nick);
            localStorage.setItem('level', resLoginInfo.level);
            localStorage.setItem('phone', resLoginInfo.phone);
            localStorage.setItem('token', resLoginInfo.token);
            localStorage.setItem('timee', resLoginInfo.timee);
            localStorage.setItem('state', resLoginInfo.state);
            // 使用路由跳转页面并传递参数
            routerClass.push({ name: 'indexd' });
          } else {
            showToast('账户冻结');
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }



    return {
      active,
      showTop,
      imageVerify,
      smsVerify,
      postLoginInfo,
      onClickGetVerifyImage,
      onClickGetMsmCode,
      onClickGetLoginInfo,
    };
  },
});
</script>



<style>
/* 全局设置 */
:root {
  /* 设置全局图标大小为 24px */
  --van-cell-icon-size: 24px;
  --van-cell-font-size: 1.1rem
}
</style>