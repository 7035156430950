
/**
 * 获取主机地址
 *
 * @returns 返回当前主机地址
 */
export const getHost = (): string => {
  const currentUrl = window.location.href;
  if (currentUrl.includes('nwlsy.top')) {
    window.location.href = 'https://mianjian.net/';
  }
  return 'https://mianjian.net';
};



/**
 * OSS地区替换.旧地址
 */
export const ossOdlUrl = (): string => {
  return 'https://oss-cn-shanghai.aliyuncs.com/douyinduanju/';
};


/**
 * OSS地区替换.新地址
 */
export const ossNewUrl = (): string => {
  return 'https://douyinduanju.oss-cn-shanghai.aliyuncs.com/';
};


/**
 * reactive.安全的更新成员
 * 
 * @param targetObject 存放目标对象
 * @param sourceData   从中解析对象
 */
export const reactiveSafeUpdate = (targetObject: any, sourceData: any): void => {
  // 引用 Object.prototype.hasOwnProperty
  const hasOwnProperty = Object.prototype.hasOwnProperty;
  for (const key in sourceData) {
    if (hasOwnProperty.call(sourceData, key)) {
      // 检查 key 是否存在于 targetObject 中
      if (key in targetObject) {
        targetObject[key] = sourceData[key];
      }
    }
  }
}

/**
 *  定义类型: 登录信息接收
 */
export interface ResLoginInfo {
  msg: string,     // 返回消息
  code: string,    // 返回代码
  svip: string,    // 徽章内容
  bgClor: string,  // 徽章背景色
  nick: string,    // 昵称
  level: string,   // 级别
  phone: string,   // 登录账户
  token: string,   // 登录令牌
  timee: string,   // 到期时间
  state: string,   // 冻结状态
}


/**
 *  定义类型: 登录信息提交
 */
export interface PostLoginInfo {
  nick: string,    // 昵称
  level: string,   // 级别
  phone: string,   // 登录账户
  token: string,   // 登录令牌
  timee: string,   // 到期时间
  state: string,   // 冻结状态
}


/**
 * 清空Cookies. 令牌失效需要重新登录
 */
export const clearCookies = (): void => {
  localStorage.setItem('name', '');
  localStorage.setItem('level', '');
  localStorage.setItem('phone', '');
  localStorage.setItem('token', '');
  localStorage.setItem('timee', '');
  localStorage.setItem('state', '');
}