<template>
    <!-- SVIP价格卡 -->
    <div
        style="position: relative; z-index: 1; width: 100%; height: 11rem;  border-radius: 10px; background-color: #f7f7f7; margin: 0.2rem;">
        <!-- 办理时间 -->
        <div style="width: 100%; height: 4rem; line-height: 4rem; text-align: center;">
            <span style="font-size: 1.6rem; color: #000; font-weight: bold;">{{ timeText }}</span>
        </div>
        <!-- 折扣价 -->
        <div style="width: 100%; height: 2rem; line-height: 2rem; text-align: center;">
            <span style="font-size: 1.2rem; color: #8a2cdc; font-weight: bold;">￥{{ discountPrice }}</span>
        </div>
        <!-- 原始价 -->
        <div style="width: 100%; height: 1.5rem; line-height: 1.5rem; text-align: center;">
            <span style="font-size: 0.9rem; color: #686868; text-decoration: line-through;">￥{{ originalPrice }}</span>
        </div>
        <!-- 限时折扣 -->
        <div style="width: 100%; height: 3rem; line-height: 3rem; text-align: center;">
            <span style="font-size: 1.0rem; color: #8a2cdc;">{{ discountTag }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SvipCard',
    props: {
        timeText: {
            type: String,
            required: true,
            default: '办理时间'
        },
        discountPrice: {
            type: String,
            required: true,
            default: '折扣价'
        },
        originalPrice: {
            type: String,
            required: true,
            default: '原始价'
        },
        discountTag: {
            type: String,
            required: true,
            default: '限时1折'
        }
    }
});
</script>

<style scoped>
/* 可根据需要自定义组件样式 */
</style>