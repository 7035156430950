<template>
    <div
        style="margin: 16px; font-size: 0.9rem; border: 1px solid skyblue; border-radius: 1rem; padding-bottom: 0.5rem;">
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">充值金额:
            </span>{{ money }}</div>
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">目标昵称:
            </span>{{ nick }}</div>
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">目标账户:
            </span>{{ hisacc }}</div>
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">操办人员:
            </span>{{ admin }}</div>
        <div style="text-align: left; margin-top: 0.5rem; padding-left: 1rem;"><span style="font-weight: bold;">操办时间:
            </span>{{ time }}</div>
    </div>
</template>

<script lang="ts">
import { showToast, showSuccessToast, showFailToast } from 'vant';
import { ref, defineComponent, onMounted } from 'vue'



// 复制卡号
export default defineComponent({
    props: {
        admin: {
            type: String,
            required: true,
            default: '操办人员'
        },
        nick: {
            type: String,
            required: true,
            default: '目标昵称'
        },
        hisacc: {
            type: String,
            required: true,
            default: '目标账户'
        },
        money: {
            type: String,
            required: true,
            default: '充值金额'
        },
        time: {
            type: String,
            required: true,
            default: '充值时间'
        },
    },
    setup(props) {
        // 组件生命周期: 组件挂载之后执行的方法
        onMounted(() => {
            console.log("组件挂载之后执行的方法");
        });


        return {
            props,
        };
    }
})
</script>

<style scoped>
/* 这里可以添加你想要的自定义样式 */
</style>