<template>
  <div :style="{ marginLeft: marginLeft + 'px', width: screenWidth + 'px', height: screenHeight + 'px' }">
    <!-- 回到顶部 -->
    <van-back-top style="margin-bottom: 5.6rem;" />
    <!-- 使用 router-view 的 v-slot 和 keep-alive 来缓存页面 -->
    <router-view v-slot="{ Component }">
      <keep-alive :include="['IndexA', 'IndexB', 'IndexC']">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <!-- Tabbar 标签栏 -->
    <van-tabbar v-model="active" :style="{ marginLeft: marginLeft + 'px', width: screenWidth + 'px' }">
      <van-tabbar-item to="/" icon="fire-o">作品</van-tabbar-item>
      <van-tabbar-item to="/b" icon="play-circle-o">教学</van-tabbar-item>
      <van-tabbar-item to="/c" icon="service-o">服务</van-tabbar-item>
      <van-tabbar-item to="/d" icon="user-circle-o">我的</van-tabbar-item>
    </van-tabbar>
    <!-- 开启底部安全区适配 -->
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>


<script lang="ts">
import { ref, nextTick, defineComponent, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';


export default defineComponent({
  setup() {
    // 后面要通过状态管理来修改 active ，达到在子页面中切换 active
    const active = ref(0);
    const screenWidth = ref(0);
    const screenHeight = ref(0);
    const marginLeft = ref(0);


    // 获取浏览器窗口的视口宽度和高度，并在窗口大小变化时自动更新。
    const handleResize = () => {
      let width = window.screen.width;
      let height = window.screen.height;
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      if (width * 1.3 > height) {
        // PC
        screenWidth.value = 618;
        screenHeight.value = height;
        marginLeft.value = (windowWidth - screenWidth.value) / 2;
        if (marginLeft.value < 0) marginLeft.value = 0;
      } else {
        // Phone
        screenWidth.value = width;
        screenHeight.value = height;
        marginLeft.value = 0;
      }
    };


    onMounted(() => {
      handleResize();
      // 注册事件
      window.addEventListener('resize', handleResize);


    });

    onBeforeUnmount(() => {
      // 删除事件
      window.removeEventListener('resize', handleResize);
    });


    return {
      active,
      screenWidth,
      screenHeight,
      marginLeft,
    };
  }
});
</script>



<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



nav {
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
