<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="卡号管理" left-text="返回" left-arrow @click-left="onClickLeft" style="font-size: 2.5rem;" />
    <!-- 卡号统计 -->
    <div class="statisticsDiv">
      <span class="statisticsSpan1">账户中财产</span>
      我的余额: <span class="statisticsSpan2">{{ queryCardInfo.money }}.00</span> 元
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">代理卡统计</span>
      已用数量: <span class="statisticsSpan2">{{ queryCardInfo.cardbSumNo }}</span> 个，剩余数量:<span class="statisticsSpan2">{{
      queryCardInfo.cardbSumOk }}</span> 个
    </div>
    <div class="statisticsDiv">
      <span class="statisticsSpan1">会员卡统计</span>
      已用数量: <span class="statisticsSpan2">{{ queryCardInfo.cardcSumNo }}</span> 个，剩余数量:<span class="statisticsSpan2">{{
      queryCardInfo.cardcSumOk }}</span> 个
    </div>
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <!-- 菜单区 -->
    <van-cell icon="shopping-cart-o" title="采购升级卡号" is-link @click="onClickOpenMenu('采购升级卡号')" />
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <van-cell icon="description-o" title="复制未用代理卡" is-link @click="onClickOpenMenu('复制未用代理卡')" />
    <van-cell icon="description-o" title="复制未用会员卡" is-link @click="onClickOpenMenu('复制未用会员卡')" />
    <van-cell icon="completed-o" title="查看已用代理卡" is-link @click="onClickOpenMenu('查看已用代理卡')" />
    <van-cell icon="completed-o" title="查看已用会员卡" is-link @click="onClickOpenMenu('查看已用会员卡')" />
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <div style="width: 100%; height: 2.0rem; line-height: 2.0rem; color: #0080ff; font-size: 0.6rem;">
      提示: 账户充值请联系本站[服务栏]中的微信客服
    </div>
    <!-- 采购升级卡号 - 级别选项卡 -->
    <van-popup closeable v-model:show="SelectCard.show1" round :style="{ width: '18rem', padding: '1rem' }">
      <div style="font-size: 1rem; font-weight: bold; margin: 1rem;">我要采购:</div>
      <van-button color="linear-gradient(to right bottom, #ead9fb, #8a2cdc, #ead9fb)"
        style="width: 15rem; height: 3rem; margin-bottom: .8rem;" @click="onClickOpenMenu('采购代理卡')">
        代理服务商 SVIP 升级卡
      </van-button>
      <van-button color="linear-gradient(to right bottom, #fedba0, #ad630c, #fedba0)"
        style="width: 15rem; height: 3rem; margin-bottom: .5rem; " @click="onClickOpenMenu('采购会员卡')">
        高级会员 VIP 升级卡
      </van-button>
      <div style="width: 100%; height: 2.0rem; line-height: 2.0rem; color: #0080ff; font-size: 0.6rem;">
        提示: 采购的升级卡在使用前永久有效
      </div>
    </van-popup>
    <!-- 采购升级卡号 - 年限选项卡 SVIP -->
    <van-popup closeable v-model:show="SelectCard.show2" round :style="{ width: '18rem', padding: '1rem' }">
      <div style="font-size: 1rem; font-weight: bold; margin: 1rem;">采购代理升级卡:</div>
      <van-button color="linear-gradient(to right bottom, #ead9fb, #8a2cdc, #ead9fb)" class="purchaseButton"
        @click="onClickBuySvipCard('1')">
        1年 SVIP 升级卡 ￥20000.00元
      </van-button>
      <van-button color="linear-gradient(to right bottom, #ead9fb, #8a2cdc, #ead9fb)" class="purchaseButton"
        @click="onClickBuySvipCard('2')">
        2年 SVIP 升级卡 ￥32000.00元
      </van-button>
      <van-button color="linear-gradient(to right bottom, #ead9fb, #8a2cdc, #ead9fb)" class="purchaseButton"
        @click="onClickBuySvipCard('3')">
        3年 SVIP 升级卡 ￥36000.00元
      </van-button>
      <van-button color="linear-gradient(to right bottom, #ead9fb, #8a2cdc, #ead9fb)" class="purchaseButton"
        @click="onClickBuySvipCard('10')">
        10年 SVIP 升级卡 ￥80000.00元
      </van-button>
    </van-popup>
    <!-- 采购升级卡号 - 年限选项卡 SVIP -->
    <van-popup closeable v-model:show="SelectCard.show3" round :style="{ width: '18rem', padding: '1rem' }">
      <div style="font-size: 1rem; font-weight: bold; margin: 1rem;">采购会员升级卡:</div>
      <van-button color="linear-gradient(to right bottom, #fedba0, #ad630c, #fedba0)" class="purchaseButton"
        @click="onClickBuyVipCard('1')">
        1年 VIP 升级卡 1000.00元
      </van-button>
      <van-button color="linear-gradient(to right bottom, #fedba0, #ad630c, #fedba0)" class="purchaseButton"
        @click="onClickBuyVipCard('2')">
        2年 VIP 升级卡 ￥1600.00元
      </van-button>
      <van-button color="linear-gradient(to right bottom, #fedba0, #ad630c, #fedba0)" class="purchaseButton"
        @click="onClickBuyVipCard('3')">
        3年 VIP 升级卡 ￥1800.00元
      </van-button>
      <van-button color="linear-gradient(to right bottom, #fedba0, #ad630c, #fedba0)" class="purchaseButton"
        @click="onClickBuyVipCard('10')">
        10年 VIP 升级卡 ￥4000.00元
      </van-button>
    </van-popup>
  </div>
</template>



<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { getHost, reactiveSafeUpdate, PostLoginInfo } from '@/api/Api';
import { showToast, showSuccessToast, showFailToast } from 'vant';
import { useRouter } from 'vue-router';
import ClipboardJS from 'clipboard';
import axios from 'axios';

/**
 *  定义类型: 卡号相关信息查询结果
 */
interface QueryCardInfo {
  msg: string,        // 返回消息
  code: string,       // 返回代码
  money: string,      // 当前账号余额
  cardbSumOk: string, // 可用代理卡数量
  cardcSumOk: string, // 可用会员卡数量
  cardbSumNo: string, // 已用代理卡数量
  cardcSumNo: string, // 已用会员卡数量
}


/**
 *  定义类型: 选择升级卡号
 */
interface SelectCard {
  show1: boolean, // 级别选项卡
  show2: boolean, // 年限选项卡 SVIP
  show3: boolean, // 年限选项卡 VIP
  show4: boolean, // 年限选项卡 VIP
  card: string,   // 选择卡号各类
  life: string,   // 选择卡号年限
}


/**
 *  定义类型: 采购升级卡号
 */
interface PurchaseCard {
  phone: string,   // 登录账户
  token: string,   // 登录令牌
  card: string,   // 选择卡号各类
  life: string,   // 选择卡号年限
}


export default defineComponent({
  components: {
  },

  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();

    // 声明类型: 登录信息提交
    let postLoginInfo = reactive<PostLoginInfo>({
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });
    // 声明类型: 卡号相关信息查询结果
    let queryCardInfo = reactive<QueryCardInfo>({
      msg: '',
      code: '',
      money: '0',
      cardbSumOk: '0',
      cardcSumOk: '0',
      cardbSumNo: '0',
      cardcSumNo: '0',
    });
    // 声明类型: 选择升级卡号
    let SelectCard = reactive<SelectCard>({
      show1: false,
      show2: false,
      show3: false,
      show4: true,
      card: '',
      life: '',
    });
    // 声明类型: 采购升级卡号
    let purchaseCard = reactive<PurchaseCard>({
      phone: '',
      token: '',
      card: '',
      life: '',
    });




    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      onClickInitialization(); // 事件: 初始化
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      postLoginInfo.nick = localStorage.getItem('nick') || '';
      postLoginInfo.level = localStorage.getItem('level') || '';
      postLoginInfo.phone = localStorage.getItem('phone') || '';
      postLoginInfo.token = localStorage.getItem('token') || '';
      postLoginInfo.timee = localStorage.getItem('timee') || '';
      postLoginInfo.state = localStorage.getItem('state') || '';
      console.log('nick: ' + postLoginInfo.nick);
      console.log('level: ' + postLoginInfo.level);
      console.log('phone: ' + postLoginInfo.phone);
      console.log('token: ' + postLoginInfo.token);
      console.log('timee: ' + postLoginInfo.timee);
      console.log('state: ' + postLoginInfo.state);
      // 如果登录信息无效 - 则重新登录
      if (postLoginInfo.nick === '' || postLoginInfo.level === '' || postLoginInfo.phone === '' || postLoginInfo.token === '' || postLoginInfo.timee === '' || postLoginInfo.state !== '0') {
        // 使用路由跳转到登录页面
        routerClass.push({ name: 'd_al' });
        return;
      }
      cardInfoQuery();  // 查询: 升级卡相关信息
    }


    /**
     * 事件: 打开菜单
     */
    function onClickOpenMenu(tag: string) {
      console.log('打开菜单: ' + tag);
      if (tag === '采购升级卡号') {
        SelectCard.show1 = true;
        return;
      }
      if (tag === '采购代理卡') {
        SelectCard.show1 = false;
        SelectCard.show2 = true;
        return;
      }
      if (tag === '采购会员卡') {
        SelectCard.show1 = false;
        SelectCard.show3 = true;
        return;
      }
      if (tag === '复制未用代理卡') {
        // 使用路由跳转页面并传递参数
        routerClass.push({ name: 'd_cr', params: { title: tag, show: 'copy' } });
        return;
      }
      if (tag === '复制未用会员卡') {
        // 使用路由跳转页面并传递参数
        routerClass.push({ name: 'd_cr', params: { title: tag, show: 'copy' } });
        return;
      }
      if (tag === '查看已用代理卡') {
        // 使用路由跳转页面并传递参数
        routerClass.push({ name: 'd_cr', params: { title: tag, show: 'record' } });
        return;
      }
      if (tag === '查看已用会员卡') {
        // 使用路由跳转页面并传递参数
        routerClass.push({ name: 'd_cr', params: { title: tag, show: 'record' } });
        return;
      }
    }



    /**
     * 事件: 采购 SVIP 升级卡
     */
    function onClickBuySvipCard(life: string) {
      console.log('SVIP 升级卡: ' + life);
      purchaseCard.life = life;
      purchaseCard.card = 'SVIP';
      buyUpgraCard(); // 购买: SVIP升级卡 或 VIP升级卡
    }


    /**
     * 事件: 采购 VIP 升级卡
     */
    function onClickBuyVipCard(life: string) {
      console.log('VIP 升级卡: ' + life);
      purchaseCard.life = life;
      purchaseCard.card = 'VIP';
      buyUpgraCard(); // 购买: SVIP升级卡 或 VIP升级卡
    }



    /**
     * 事件: 定义复制文本的函数
     * 
     * @param textToCopy 复制的内容
     */
    function onClickCopyText(textToCopy: string) {
      let clipboard: ClipboardJS = new ClipboardJS('.van-button', {
        text: () => textToCopy,
      });
      clipboard.on('success', () => {
        // 正确调用 Toast
        showSuccessToast('复制成功！');
      });
      clipboard.on('error', () => {
        // 正确调用 Toast
        showFailToast('复制失败');
      });
    }



    // 事件: 返回到上一页
    const onClickLeft = () => history.back();



    /**
       * 查询: 升级卡相关信息
       */
    async function cardInfoQuery() {
      if (postLoginInfo.phone.length !== 11 || postLoginInfo.token.length !== 64) {
        showToast('请重新登录');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=cardInfoQuery", postLoginInfo, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(queryCardInfo, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(queryCardInfo, parsedData.data);
          }
          // 如果查询卡号相关信息失败
          if (queryCardInfo.code !== '0') {
            showToast('失败: ' + queryCardInfo.msg);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 购买: SVIP升级卡 或 VIP升级卡
     */
    async function buyUpgraCard() {
      purchaseCard.phone = postLoginInfo.phone;
      purchaseCard.token = postLoginInfo.token;
      if (purchaseCard.phone.length !== 11 || purchaseCard.token.length !== 64) {
        showToast('请重新登录');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=buyUpgraCard", purchaseCard, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果购买升级卡号成功
          if (parsedData.data.code === '0') {
            showToast('购买成功, 请去对应类目中复制');
            cardInfoQuery();  // 查询: 升级卡相关信息
          } else {
            showToast('失败: ' + parsedData.data.msg);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    return {
      queryCardInfo,
      SelectCard,
      onClickLeft,
      onClickOpenMenu,
      onClickBuyVipCard,
      onClickBuySvipCard,
      onClickCopyText,
    };
  },
});
</script>



<style>
/* 全局设置 */
:root {
  /* 设置全局图标大小为 24px */
  --van-cell-icon-size: 24px;
  --van-cell-font-size: 1.1rem
}



/** 统计 - DIV */
.statisticsDiv {
  font-size: 0.8rem;
  margin: 0.5rem;
  text-align: left;
  margin-bottom: 1rem;
}

/** 统计 - Span1 */
.statisticsSpan1 {
  font-weight: bold;
  margin-left: 1rem;
}

/** 统计 - Span2 */
.statisticsSpan2 {
  font-weight: bold;
  color: #0080ff;
}

/** 采购按钮 */
.purchaseButton {
  width: 15rem;
  height: 2.5rem;
  margin-bottom: 0.3rem;
}
</style>