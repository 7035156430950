<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="高级会员办理价目" left-text="返回" left-arrow @click-left="onClickLeft" />
    <!-- 九宫格海报列表 -->
    <van-grid :border="false" :column-num="2" class="van-grid">
      <van-grid-item v-for="(item, index) in vipItems" :key="index">
        <VipCard :timeText="item.timeText" :discountPrice="item.discountPrice" :originalPrice="item.originalPrice"
          :discountTag="item.discountTag" />
      </van-grid-item>
    </van-grid>
    <!--为何收费（收费原因）-->
    <div
      style="position: relative; width: 100%; height: 36px; line-height: 36px; background-color: #fff; margin-bottom: 16px; cursor: pointer;">
      <span style="font-size: 1.0rem; color: #ff0000;" @click="onClickGoto('charge')">本平台为何收费？为什么不能免费？！</span>
    </div>
    <!-- 自动义分割线 -->
    <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      style="margin-top: 3.0rem;">客服微信二维码</van-divider>
    <!-- 九宫格海报列表 -->
    <van-grid :border="false" :column-num="3" class="van-grid">
      <van-grid-item v-for="(item, index) in weChatItems" :key="index">
        <van-image width="6rem" height="6rem" :src="item.image" />
        <span style="font-size: 1.0rem; margin: 0.3rem;">{{ item.name }}</span>
      </van-grid-item>
    </van-grid>
    <div style="font-size: 1.1rem;">工作时间: 09:00 - 20:00</div>
    <div style="height: 188px;"></div>

    <!-- 嵌入子路由组件 -->
    <RouterView></RouterView>
  </div>
</template>




<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import VipCard from '@/components/VipCard.vue';


// 定义类型: 代理商价目
interface VipItem {
  timeText: string,         // 年限
  discountPrice: string,    // 现价
  originalPrice: string,    // 原价
  discountTag: string,      // 折扣
}


// 定义类型: 客服信息类
interface WeChatItem {
  name: string,         // 客服名称
  image: string,        // 微信二维码
}



export default defineComponent({
  components: {
    VipCard,
  },
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);

    const vipItems: VipItem[] = [
      {
        timeText: '十年',
        discountPrice: '19999.00',
        originalPrice: '99999.99',
        discountTag: '限时2折'
      }, {
        timeText: '三年',
        discountPrice: '8999.00',
        originalPrice: '29999.99',
        discountTag: '限时3折'
      }, {
        timeText: '二年',
        discountPrice: '7999.00',
        originalPrice: '19999.99',
        discountTag: '限时4折'
      }, {
        timeText: '一年',
        discountPrice: '4999.00',
        originalPrice: '9999.99',
        discountTag: '限时5折'
      },
    ]


    const weChatItems: WeChatItem[] = [
      {
        name: '小燕',
        image: require('@/assets/image/weChat1.png')
      },
      {
        name: '小雨',
        image: require('@/assets/image/weChat2.png')
      },
      {
        name: '小婧',
        image: require('@/assets/image/weChat3.png')
      },
    ]


    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");

    });



    // 点击事件: 返回上一页
    const onClickLeft = () => history.back();


    // 处理点击事件的函数
    const onClickGoto = (destination: string): void => {
      console.log(`即将跳转到: ${destination}`);
      if (destination === 'charge') {
        routerClass.push({ name: 'c_cr' })
      }


    };



    return {
      active,
      vipItems,
      weChatItems,
      onClickLeft,
      onClickGoto,
    };
  },
});
</script>


<style>
/* 宫格列表 - 必须重置，否则有该死的 margin: 1rem */
.van-grid {
  padding: 0.1rem !important;
  margin: 0rem !important;
}
</style>