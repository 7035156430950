import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
import { Toast } from 'vant';
import 'vant/lib/index.css'; // 确保 Vant 样式完整引入
import 'amfe-flexible'; // 引入 amfe-flexible 以动态设置 rem 基准值
import router from './router'
import store from './store'

function setRem() {
    let fontSize = 0;
    let designWidth = 0;
    const baseSize = 16;
    const clientWidth = document.documentElement.clientWidth || window.innerWidth;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    // console.log('clientWidth:' + clientWidth + ', clientHeight:' + clientHeight);
    if (clientWidth > clientHeight) {
        // 电脑端: 横屏
        fontSize = 24;
    } else {
        // 手机端: 坚屏
        designWidth = 375;
        fontSize = (clientWidth / designWidth) * baseSize;
    }
    document.documentElement.style.fontSize = `${fontSize}px`;
    console.log('fontSize: ' + document.documentElement.style.fontSize);

}
window.addEventListener('resize', setRem);
setRem();
createApp(App).use(Vant).use(Toast).use(store).use(router).mount('#app')



