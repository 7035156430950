<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="收费原因解答" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div style="line-height: 1.5rem;">

      <div style="padding: 1rem; font-size: 0.8rem;  text-indent: 2rem; letter-spacing: 1px;">
        <div style="margin-bottom: 1rem; text-align: left; font-size: 0.8rem;  ">
          写在前面：近期，随着短剧行业的火爆，市面上涌现出了不少【短剧二创代剪】公司或团体，由于人力剪辑成本较高，他们普遍的收费标准为：100.00元 至 150.00元
          上下代剪辑一部作品，其费用着实不低！然而，我们的VIP会员，除以365天，每天只要几元钱，分摊到5部作品中，每部作品的收费成本都不到1.00元。两方费用成本高下立判！并且，在作品二创的质量上，人工剪辑 与
          AI剪辑，差距并不明显， 反而，我们AI剪辑的作品，每天都有几百万播放量的爆火玩家诞生！
        </div>
        <div style="color: #000; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          收费原因解答如下:
        </div>
      </div>
      <!-- 收费原因的解答 -->
      <div
        style="padding: 1rem; font-size: 0.8rem;  text-indent: 2rem; text-align: left; letter-spacing: 2px; color: #0080ff;">
        <div style="margin-bottom: 2rem;" v-for="(item, index) in reasonItems" :key="index">{{
      index + 1 }}. {{
      item.text
    }}</div>
      </div>
      <div style="padding: 1rem; font-size: 0.8rem;  text-indent: 2rem; letter-spacing: 1px;  margin-bottom: 200px;">
        <div style="margin-bottom: 1rem; color: #232323; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          虽然我们有诸多成本，虽然我们有不得不收费的窘迫，但是，划算吗？谈划不划算，那就必需要有参考对象，以二线城市剪辑师来说，平均月薪在6000左右，一年薪水就是8万左右。而且，双休的情况下他每月只会工作22天，相比我们的AI是全年无休的。另外，一部20分钟左右的作品，专业剪辑人员，平均也要2-4个小时才能处理完毕，大部分人一天编辑5部作品，都会有些力不从心！这样算下来，您还会觉得我们的VIP没有性价比吗？！
        </div>
        <div style="margin-bottom: 1rem; color: #232323; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          也许您会说，我为什么要和专业剪辑人员的薪资来比呢？我自己剪辑不行吗？行，当然行！但是，您的时间就不是成本了吗？您的时间就不能换来报酬了吗？试想一下，专业剪辑人员剪辑一部20分钟的作品，平均也要2-4个小时，那么您一天剪辑3-5部作品又要多久呢？把这些时间省下来随便上一份班，您又能赚多少呢？！所以，时间就是金钱呀！省时间，就是在省钱哦！
        </div>
        <div style="margin-bottom: 1rem; color: #232323; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          也许您会说，我只是想试试。但是我想告诉您的是：试一试的心态，从一开始，您就已经出局了！没有拼搏的信念，又岂有随随便便的成功呢？做抖音挣到钱的人多吗？从全网范围来讲，很多！但从比率上来讲，很低！非常低！为什么呢？原因很复杂，一言道不尽，但可以肯定的是，其中最多原因就是：半途而废！
        </div>
        <div style="margin-bottom: 1rem; color: #232323; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          为什么会半途而废呢？因为种种原因坚持不下去啊，其中最大的原因就是：
          投入率和回报率不成正比！简单讲就是：假如您每天花10个小时在抖音上，半年挣不到5000元，您还能坚持下去吗？！大多数人，都不能，因为大量的时间投入、精神投入在没有丰厚回报的情况下都会造成多方的矛盾与冲突！比如：娃儿没人接了！饭菜没人做了！地板没人拖了！臭袜子没人洗了！马桶没人刷了！等等...一地鸡毛...就是压垮您放弃的最后一根稻草！何况，往往还是双重或多重打击！说到底，迫使多数人半途而废的原因，不外乎就是时间有限！精力有限！没有分身术啊！
        </div>
        <div style="margin-bottom: 1rem; color: #0080ff; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          没错。很多人从事抖音兼职，一开始都以为自己的时间很充足，精力很充足，只有熬过几个月的人才知道，其实自己的条件并没有想象的那么充裕。从咬牙坚持，到骑虎难下，到半途而废，正是高估了自身的条件，与低估了从事抖音二创的投入！其实，个人想要做好抖音，想在抖音赚钱，投入率是很高的，并没有宣传机构们花言巧遇说的轻松，尤其是时间不足和精力不足的人，从开始，他们其实就不具备尝试这行的资格！更何况，在抖音创业，黎明之前都要途径黑暗，日复一日的坚持，再加上高强度的投入率，本就不易！决定做一件事很难，而坚持不懈，则更难！
        </div>
        <div style="margin-bottom: 1rem; color: #0080ff; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          是的。免剪，免剪。我们做这个平台，做这件事，看重的商业价值，就在于AI模型可以解决大量枯燥耗时的工作，让时间不足的人们，精力不足的人们，可以解放双手，解放时间，有机会参与到这个赛道上来。把沉重的事情，变的轻松化；把耗时的事件，变的快捷化；把煎熬的事情，变的快乐化；给条件不够的人，创建更多的条件，这些，就是我们打造这个平台的初衷！
        </div>
        <div style="margin-bottom: 1rem; color: #0080ff; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          而您成为我们平台的 VIP 会员就不一样了，完全解放双手，每天只要十分钟上传一下作品就可以了，您的生活规律，完全没有被您的抖音创业想法给打破掉，生活完全可以一如既往。这，难道不是最大的优点吗？！
        </div>
        <div style="margin-bottom: 1rem; color: #0080ff; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          或许，开发这个平台，即不伟大，也不高尚！平台能做的事，仅仅只是代替了短剧行业中，一个个剪辑师的工作。没错，平台不是外挂，也开不了挂，它打破不了抖音的桎梏，改写不了抖音的规则，要在抖音上赚钱，该走的路，我们还是要一步一步的走。
        </div>
        <div style="margin-bottom: 1rem; color: #0080ff; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          但是，短剧剪辑的时间成本与精力成本，对于很多普通人来说，已经是一坐翻不过去的高山！要知道，并不是每个人都是一个好学分子，都愿意学习剪辑技巧！即使愿意学，也不是每个都是学霸，都能学的会！就算学会了，也不一定就能学的精，能不能过审，判不判搬运，都不是一件容易事！哪怕这些问题都能解决，还会遇上前面说的时间问题和精力问题，毕竟解决问题是需要大量时间和精力的，日复一日的坚持就更难了！
        </div>
        <div style="margin-bottom: 1rem; color: #0080ff; text-align: left; font-weight: bold; font-size: 0.9rem;  ">
          不过，加入我们的 VIP 会员以后，一切就都不同了。因为 AI模型， 已经帮我们解决了99.9999%的工作量，我们只需要动动手指，就可以发布作品，就可以坐等抖音官方的短剧分红收益哦！
        </div>
        <div style="margin-bottom: 1rem; color: #ff0000; text-align: left; ">
          作为一个普通人，在经济下滑的大时代背景下，我们每个人活的都很不容易！尝试未必能成功，但成功必然要尝试！一起加油吧！！！
        </div>
        <div style="margin-bottom: 1rem; color: #232323; text-align:center; ">2024.09.01
        </div>
      </div>
    </div>

    <RouterView></RouterView>
  </div>
</template>




<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import VipCard from '@/components/VipCard.vue';





// 定义类型: 原因解答
interface ReasonItem {
  id: string,
  text: string,
}



export default defineComponent({
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);


    const reasonItems: ReasonItem[] = [
      {
        id: 'null',
        text: '硬件成本。本平台的所有作品均由AI模型剪辑生成而来，免去了大家剪辑的繁琐过程，但承载AI模型的载具自然离不开 高性能的 GPU 与 CPU， 而本平台所用的每一块 4090TI 显卡都高达数万元，且其它配件也高达近万元，前期硬件的投入费用是一大笔的数字！'
      }, {
        id: 'null',
        text: '电费成本。大家应该知道，高性能的CPU和GPU也意味着高功耗，它们都是吞电巨兽！平台每个 VIP 每天可以下载 5部作品，每5部作品的平均用电量不少于 0.5度，按工业用电标准至少大于 0.50元，乘以 365天，承载一个VIP会员一年的用电费用就约为：182.00元'
      }, {
        id: 'null',
        text: '流量成本。在所有长期固定成本中，流量成本位居榜首！众所周知，视频的字节长度非常大，普通家用网络无法承载平台每一位用户的高速下载需求，而企业运营通常会租用IDC的流量池，比如国内知名的：阿里云 和 腾讯云。目前我们平台所用的作品分发链正是阿里云的OSS服务，该服务按流量计费，每GB费用为：0.50元。我们平台平均每部作品的流量在 1GB左右，每个VIP 每天可以下载5部作品，平均约为：5GB， 5GB x 0.50元，每个VIP 每天的流量成本就是： 2.50元， 乘以 365天，一年约为：912.00元。'
      }, {
        id: 'null',
        text: '建设成本。本平台背后的技术支撑是AI模型，因此，在AI模型的训练上、应用上，以及平台的前台与后台的开发上，都是一笔笔高昂的经济投入 与  时间投入！'
      }, {
        id: 'null',
        text: '维护成本。任何一个平台，想要健壮稳定的运营下去，必然都离不开各种维护的投入，而维护成本不仅仅只有人力成本，也会常常伴随着各种物力成本，比如设备零件更替和基础设施上的保养！'
      }, {
        id: 'null',
        text: '人员成本。企业的基本单元是由人构成的，而每一个人，都离不开基本的生活刚需。不论是企业的投资者、管理者、基层者，都要吃饭，都要生存。作为成年人，我们每个人都是家庭的支柱，都要为家庭的经济贡献一份力量，这是无可厚非的，也是我们无法逃避的。所以，企业也必然要为每一位成员支付薪水，以此来保障大家的基本生活！'
      }, {
        id: 'null',
        text: '其它成本。有很多成本，往往也是没有办法计算的。很多帐，就像我们的生活，算起来或许是一地鸡毛，但累加起来，往往又是一身冷汗...'
      }
    ]


    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");

    });



    // 点击事件: 返回上一页
    const onClickLeft = () => history.back();


    // 处理点击事件的函数
    const onClickGoto = (destination: string): void => {
      console.log(`即将跳转到: ${destination}`);
      if (destination === 'svip') {
        routerClass.push({ name: 'c_as' })
      }
      if (destination === 'vip') {
        routerClass.push({ name: 'c_bv' })
      }


    };



    return {
      active,
      reasonItems,
      onClickLeft,
      onClickGoto,
    };
  },
});
</script>


<style>
/* 宫格列表 - 必须重置，否则有该死的 margin: 1rem */
.van-grid {
  padding: 0.1rem !important;
  margin: 0rem !important;
}
</style>