<template>
  <div>
    <!-- 这里不再直接创建 video 元素， 只创建一个占位符容器 -->
    <div ref="videoContainer"></div>
    <slot name="customContent"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref, PropType, defineEmits } from 'vue';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';








export default defineComponent({
  props: {
    src: {
      type: String as PropType<string>,
      required: true
    },
    playerWidth: {
      type: String as PropType<string>,
      default: '500px'
    },
    playerHeight: {
      type: String as PropType<string>,
      default: '900px'
    },
    poster: {
      type: String as PropType<string>,
      default: ''  // 默认没有海报图片
    },
    autoplay: {
      type: String as PropType<string>,
      default: 'false' // 默认不自动播放
    },
    isBigPlayButton: {
      type: Boolean as PropType<boolean>,
      default: true // 是否开启大按钮
    }
  },
  // 添加 emits 选项来声明你将使用的自定义事件
  emits: ['message-to-player'],
  setup(props, { emit }) {
    // 发送给父组件的消息函数
    const sendMessageToParent = (msg: string) => {
      emit('message-to-player', msg);
    };

    const videoContainer = ref<HTMLDivElement | null>(null);
    let player: VideoJsPlayer | null = null;

    // 初始化播放器函数
    const initializePlayer = (videoSrc: string) => {
      // 检查容器是否存在
      if (!videoContainer.value) return;
      // 清空 videoContainer
      clearContainer(videoContainer.value);
      // 创建 video 元素
      const videoEl = document.createElement('video');
      videoEl.className = 'video-js vjs-default-skin';
      videoEl.style.width = props.playerWidth;
      videoEl.style.height = props.playerHeight;
      videoEl.controls = true;
      videoEl.preload = props.autoplay === 'true' ? 'auto' : 'none';
      videoEl.autoplay = props.autoplay === 'true' ? true : false;
      videoEl.poster = props.poster;
      // 创建 source 元素
      const source = document.createElement('source');
      source.src = videoSrc;
      source.type = 'video/mp4';
      videoEl.appendChild(source);

      // 将 video 元素添加到容器中
      videoContainer.value?.appendChild(videoEl);

      // 使用 videojs 库初始化播放器
      player = videojs(videoEl, {
        preload: props.autoplay === 'true' ? 'auto' : 'none',
        autoplay: props.autoplay === 'true' ? true : false,
        bigPlayButton: props.isBigPlayButton,
      });

      // 监听视频准备事件，确保视频在准备好后播放
      player?.ready(() => {
        // 判断是否需要自动播放.这句代码起关键作用
        if (props.autoplay === 'true') player?.play();
      });

      // 监听视频播放事件等，传入 player 实例
      videoPlayerEvents(player);
    }


    // 清空容器
    const clearContainer = (container: HTMLDivElement | null) => {
      if (container) {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
      }
    };


    // 监听各种事件状态
    const videoPlayerEvents = (player: VideoJsPlayer) => {
      player.on('play', () => {
        console.log("视频正在播放...");
        sendMessageToParent('play-yes');
      });
      player.on('pause', () => {
        console.log("视频已暂停");
        sendMessageToParent('pause-yes');
      });
      player.on('ended', () => {
        console.log("视频播放结束");
        sendMessageToParent('ended-yes');
      });
      player.on('timeupdate', () => {
        // console.log("当前播放时间: ", player!.currentTime());
        sendMessageToParent('timeupdate-' + player!.currentTime());
      });
      player.on('volumechange', () => {
        console.log("音量已更改: ", player!.volume());
        sendMessageToParent('volumechange-' + player!.volume());
      });
      player.on('seeking', () => {
        console.log("正在跳转到新位置...");
        sendMessageToParent('seeking-yes');
      });
      player.on('seeked', () => {
        console.log("跳转完成");
        sendMessageToParent('seeked-yes');
      });
      player.on('loadeddata', () => {
        console.log("视频数据已加载");
        sendMessageToParent('loadeddata-yes');
      });
      player.on('loadedmetadata', () => {
        console.log("视频元数据已加载");
        sendMessageToParent('loadedmetadata-yes');
      });
      player.on('canplay', () => {
        console.log("视频可以播放");
        sendMessageToParent('canplay-yes');
      });
      player.on('canplaythrough', () => {
        console.log("视频已完全加载，可以流畅播放");
        sendMessageToParent('canplaythrough-yes');
      });
      player.on('waiting', () => {
        console.log("等待缓冲...");
        sendMessageToParent('waiting-yes');
      });
      player.on('playing', () => {
        console.log("视频实际开始播放");
        sendMessageToParent('playing-yes');
      });
      player.on('stalled', () => {
        console.log("视频意外停止加载");
        sendMessageToParent('stalled-yes');
      });
      player.on('error', () => {
        console.error("播放时发生错误: ", player!.error());
        sendMessageToParent('error-yes');
      });
      player.on('durationchange', () => {
        console.log("视频时长已改变: ", player!.duration());
        sendMessageToParent('durationchange-yes');
      });
      player.on('fullscreenchange', () => {
        console.log("全屏模式已更改");
        sendMessageToParent('fullscreenchange-yes');
      });
      player.on('progress', () => {
        console.log("正在加载视频数据...");
        sendMessageToParent('progress-yes');
      });
      player.on('ratechange', () => {
        console.log("播放速度已更改: ", player!.playbackRate());
        sendMessageToParent('ratechange-yes');
      });
      player.on('abort', () => {
        console.log("视频加载已取消");
        sendMessageToParent('abort-yes');
      });
      player.on('loadstart', () => {
        console.log("浏览器开始加载媒体数据");
        sendMessageToParent('loadstart-yes');
      });
      player.on('resize', () => {
        console.log("视频大小已更改");
        sendMessageToParent('resize-yes');
      });
    };



    onMounted(() => {
      // 子组件挂载完成后可以做一些初始化工作
      sendMessageToParent('initialize-yes');
      // 如何使用发模板参数作为初始化
      if (props.src.length > 0) {
        // 初始化播放器函数
        initializePlayer(props.src);
      }

    });

    // 清理函数，确保当组件卸载时销毁播放器实例
    onBeforeUnmount(() => {
      if (player) {
        player.dispose();
        player = null;
      }
    });



    // 直接使用 props 的值
    return {
      videoContainer,
      initializePlayer,
      videoPlayerEvents,
      props // 返回 props 对象， 方便在模板中直接使用
    };
  }
});
</script>

<style>
/* 居中:播放按钮 */
.video-js .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.video-js .vjs-download-button {
  display: none !important;
}
</style>