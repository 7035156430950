<template>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">{{ name }}</van-divider>
    <van-row style="padding-left: 0.3rem; padding-right: 0.3rem;">
        <van-col span="11">
            <!-- 视频播放器 -->
            <!-- 
            <VideoPlayer :src="downl" :poster="image" playerWidth="10rem" playerHeight="16rem" :autoplay="false">
            </VideoPlayer>
            -->
            <!-- 图片展示器 -->
            <van-swipe :autoplay="3000" lazy-render>
                <van-swipe-item>
                    <van-image width="10rem" height="16.4rem" :src="cover" radius="8px" />
                </van-swipe-item>
                <van-swipe-item>
                    <van-image width="10rem" height="16.4rem" :src="image" radius="8px" />
                </van-swipe-item>
            </van-swipe>
        </van-col>
        <van-col span="13" style="padding-left: 0.6rem;">
            <!-- 设置垂直间距 -->
            <van-row :gutter="[0, 10]">
                <van-col span="22" class="left-align"><span>短剧名称: </span>{{ works }}</van-col>
                <van-col span="22" class="left-align"><span>剧情分类: </span>{{ sort }}</van-col>
                <van-col span="22" class="left-align"><span>作品时长: </span>{{ duration }}</van-col>
                <van-col span="22" class="left-align"><span>上架时间: </span>{{ timeu }}</van-col>
                <van-col span="22" class="left-align"><span>拿下时间: </span>{{ timet }}</van-col>
                <van-field readonly v-model="copyTitle" rows="4" autosize type="textarea" maxlength="200"
                    placeholder="AI随机生成标题" show-word-limit
                    style="font-size: 0.8rem; margin-right: 0.6rem; margin-top: 0.2rem; border: 1px solid #d5e9ff; border-radius: 1rem; " />
            </van-row>
        </van-col>
    </van-row>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">请您主宰</van-divider>
    <van-space>
        <van-button size="small" type="primary" style="height: 2rem;"
            @click="onClickCopyText(copyWorks)">复制剧名</van-button>
        <van-button size="small" type="primary" style="height: 2rem;" @click="onClickRequest('生成标题')">生成标题</van-button>
        <van-button size="small" type="primary" style="height: 2rem;"
            @click="onClickCopyText(copyTitle)">复制标题</van-button>
        <van-button size="small" type="primary" style="height: 2rem;" @click="onClickRequest('下载封面')">下载封面</van-button>
        <van-button size="small" type="primary" style="height: 2rem;" @click="onClickRequest('下载作品')">下载作品</van-button>
    </van-space>
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1; margin-top: 1rem; margin-bottom: 1rem;"></div>
</template>

<script lang="ts">
import { getHost, reactiveSafeUpdate, PostLoginInfo, clearCookies } from '@/api/Api';
import { showToast, showSuccessToast, showFailToast } from 'vant';
import { ref, reactive, defineComponent, onMounted } from 'vue'
import VideoPlayer from '@/components/VideoPlayer.vue';
import ClipboardJS from 'clipboard';
import axios from 'axios';


// 作品管理
export default defineComponent({
    components: {
        // VideoPlayer
    },
    props: {
        ida: {
            type: String,
            required: true,
            default: '记录编号'
        },
        video: {
            type: String,
            required: true,
            default: '预览视频'
        },
        cover: {
            type: String,
            required: true,
            default: '短剧封面'
        },
        image: {
            type: String,
            required: true,
            default: '作品封面'
        },
        downl: {
            type: String,
            required: true,
            default: '作品下载'
        },
        name: {
            type: String,
            required: true,
            default: '作品名称'
        },
        works: {
            type: String,
            required: true,
            default: '短剧名称'
        },
        duration: {
            type: String,
            required: true,
            default: '作品秒数'
        },
        sort: {
            type: String,
            required: true,
            default: '剧情分类'
        },
        timeu: {
            type: String,
            required: true,
            default: '上架时间'
        },
        timet: {
            type: String,
            required: true,
            default: '拿下时间'
        },
        timed: {
            type: String,
            required: true,
            default: '下载时间'
        },
        keymd5: {
            type: String,
            required: true,
            default: '短剧标识'
        }
    },
    setup(props) {
        let copyWorks = ref(props.works);
        let copyTitle = ref('可以使用咱们的AI技术给您随机生成标题哦...');
        // 组件生命周期: 组件挂载之后执行的方法
        onMounted(() => {
            console.log("组件挂载之后执行的方法");
        });



        /**
         * 事件: 定义复制文本的函数
         * 
         * @param textToCopy 复制的内容
         */
        function onClickCopyText(textToCopy: string) {
            let clipboard: ClipboardJS = new ClipboardJS('.van-button', {
                text: () => textToCopy,
            });
            clipboard.on('success', () => {
                // 正确调用 Toast
                showSuccessToast('复制成功');
            });
            clipboard.on('error', () => {
                // 正确调用 Toast
                showFailToast('复制失败');
            });
        }

        /**
         * 事件: 执行网络请求
         * 
         * @param tag 请求标志
         */
        function onClickRequest(tag: string) {
            console.log('请求标志: ' + tag);
            let phone = localStorage.getItem('phone') || '';
            let token = localStorage.getItem('token') || '';
            if (tag === '生成标题') {
                randNewTitles(token, phone);// 登录用户: AI随便生成作品的发布标题
                return;
            }
            if (tag === '下载封面') {
                // 登录用户: 下面当前作品的相关文件
                showToast('下载封面');
                downloadFile(props.works + '.png', props.cover);
                return;
            }
            if (tag === '下载作品') {
                showToast('下载作品');
                // 登录用户: 下面当前作品的相关文件
                downloadFile(props.name + '.mp4', props.downl);
                return;
            }
        }


        /**
         * 登录用户: AI随机生成作品的发布标题
         */
        async function randNewTitles(token: string, phone: string) {
            let postJson = reactive({
                token: token,
                phone: phone,
                keymd5: props.keymd5,
                works: props.works,
                sort: props.sort,
            });
            try {
                let response = await axios.post(getHost() + "/php/account.php?func=randNewTitles", postJson, {});
                if (response.status != 200) {
                    showToast('服务端正在维护, 请稍后重试');
                    return;
                }
                let parsedData = response.data
                if (parsedData.code != '0') {
                    showToast('发生异常.code: [' + parsedData.code + ']');
                    return;
                }
                try {
                    copyTitle.value = parsedData.data.title;
                    showToast('生成完毕');
                } catch (error) {
                    console.error('Error parsing JSON data:', error);
                }
            } catch (error) {
                showToast('当前网络错误, 请稍后重试');
            }
        }


        /**
         * 下载文件: 下载当前作品的相关文件
         * 
         * @param name 保存名称
         * @param url   下载链接
         */
        function downloadFile(name: string, url: string) {
            const link = document.createElement('a');
            link.href = url;
            link.download = name;   // 你可以根据需要自定义文件名
            // link.target = '_blank'; // 在新窗口打开下载链接
            link.target = '_self'; // 在本窗口打开下载链接
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 删除创建的 link 元素
        }


        return {
            copyWorks,
            copyTitle,
            onClickCopyText,
            onClickRequest,
        };
    }
})
</script>

<style scoped>
/* 作品详情 */
.left-align {
    font-size: 0.8rem;
    text-align: left;
    /* 水平靠左 */
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 超出内容隐藏 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}
</style>