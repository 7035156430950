import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_back_top = _resolveComponent("van-back-top")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item")!
  const _component_van_tabbar = _resolveComponent("van-tabbar")!
  const _component_van_number_keyboard = _resolveComponent("van-number-keyboard")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ marginLeft: _ctx.marginLeft + 'px', width: _ctx.screenWidth + 'px', height: _ctx.screenHeight + 'px' })
  }, [
    _createVNode(_component_van_back_top, { style: {"margin-bottom":"5.6rem"} }),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, { include: ['IndexA', 'IndexB', 'IndexC'] }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(_component_van_tabbar, {
      modelValue: _ctx.active,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      style: _normalizeStyle({ marginLeft: _ctx.marginLeft + 'px', width: _ctx.screenWidth + 'px' })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tabbar_item, {
          to: "/",
          icon: "fire-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode("作品")
          ]),
          _: 1
        }),
        _createVNode(_component_van_tabbar_item, {
          to: "/b",
          icon: "play-circle-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode("教学")
          ]),
          _: 1
        }),
        _createVNode(_component_van_tabbar_item, {
          to: "/c",
          icon: "service-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode("服务")
          ]),
          _: 1
        }),
        _createVNode(_component_van_tabbar_item, {
          to: "/d",
          icon: "user-circle-o"
        }, {
          default: _withCtx(() => [
            _createTextVNode("我的")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "style"]),
    _createVNode(_component_van_number_keyboard, { "safe-area-inset-bottom": "" })
  ], 4))
}