<template>
    <!-- 黄色会员卡 -->
    <div style="position: relative; width: 23.5rem; height: 11rem;">
        <div
            style="position: absolute; z-index: -9; width: 90%; margin-left: 5%; height: 10.5rem; border-radius: 0.95rem; background-image: linear-gradient(135deg, #fedba0,#fecc78 40%, #fee3b6);">
        </div>
        <div style="position: absolute; z-index: -8; width: 90%; margin-left: 5%;">
            <div style="position: absolute; z-index: -8; width: 100%; top: 1.37rem;">
                <span style="font-size: 2.0rem; font-weight: bolder; color: #ad630c;">{{ title }}</span>
            </div>
            <div style="position: absolute; z-index: -8; width: 100%; top: 4.53rem;">
                <span style="font-size: 1.0rem; color: #ad630c;">{{ subtitle }}</span>
            </div>
            <div style="position: absolute; z-index: -8; width: 100%; top: 7.26rem;">
                <span style="font-size: 1.2rem; color: #ad630c;">{{ featureText }}</span>
            </div>
            <div
                style="position: absolute; z-index: -8; background-color: #fdeecd; width: 4.63rem; height: 2rem; line-height: 1.5rem; text-align: center; right: 0; top: 4.53rem; border-bottom-left-radius: 0.95rem; border-top-left-radius: 0.95rem;">
                <span style="font-size: 1.0rem; color: #ad630c;">{{ actionText }}</span>
            </div>
            <div style="position: absolute; z-index: -8; top: 3.79rem;">
                <img src="@/assets/icon/crown4.png" alt="皇冠图标" style="width: 2.53rem; margin-left: 1.21rem;" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'VipShow',
    props: {
        title: {
            type: String,
            required: true,
            default: '普通会员'
        },
        subtitle: {
            type: String,
            required: true,
            default: 'VIP暂未开通'
        },
        featureText: {
            type: String,
            required: true,
            default: '普通主播无限免费试听和导出'
        },
        actionText: {
            type: String,
            required: true,
            default: '可开通'
        }
    }
})
</script>

<style scoped>
/* 这里可以添加你想要的自定义样式 */
</style>